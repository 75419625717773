import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  Input,
  message,
  Spin,
  Button,
  Form,
  Select,
  Drawer,
} from "antd";
import { useTranslation } from "react-i18next";
import { EditFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import moment from "moment";
import {
  getAllEngineerProject,
  getAllAdminComapnies,
  getAllEngineers,
  updateCompanyProject,
  getCompanyProject,
  getAllDocumentTypes,
} from "../../../Api/restApi";
const { Search } = Input;
const { Option } = Select;

export default function Engineer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsDetials, setAllProjectsDetials] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const [form] = Form.useForm();
  const [createLoading, setIsCreateLoading] = useState(false);
  const [allEngineers, setAllEngineers] = useState([]);
  const [selectProjectData, setSelectProjectData] = useState({});
  const [addDrawer, setAddrawer] = useState(false);
  const [documentType, setDocumentType] = useState([]);

  // Close drawer
  const closeAddDrawer = () => {
    setAddrawer(false);
  };

  const columns = [
    {
      title: t("Engineer.Dashboard.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Engineer.Dashboard.TC02"),
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.length - b.created_at.length,
    },
    {
      title: t("Engineer.Dashboard.TC03"),
      dataIndex: "companiesAssigned",
      sorter: (a, b) => a.companiesAssigned.length - b.companiesAssigned.length,
      render: (data, record) => handleAssignCompany(data),
    },
    {
      title: t("Engineer.Dashboard.TC04"),
      dataIndex: "document_types",
      sorter: (a, b) => a.document_types.length - b.document_types.length,
      render: (data, record) => handleDocumentType(data),
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  const handleDocumentType = (data) => {
    let name = [];
    if (data !== null && data !== "") {
      data.map((item) => {
        documentType.map((value) => {
          if (value?.id === item) {
            name.push(value.name);
          }
          return name;
        });
        return name;
      });
    }
    return name.toString();
  };

  const handleAssignCompany = (data) => {
    let name = [];
    if (data !== null && data !== "") {
      data.map((item) => {
        companiesData.map((value) => {
          if (value?.company_id === item) {
            name.push(value.company_name);
          }
          return name;
        });
        return name;
      });
    }
    return name.toString();
  };

  // Edit drawer open
  const showEditDrawer = (record) => {
    setSelectProjectData(record);
    let data = {
      ...record,
      assigned_engineer_id: JSON.parse(
        window.localStorage.getItem("user-attributes")
      ).sub,
    };
    form.setFieldsValue(data);
    setAddrawer(true);
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allProjectsDetials.filter((label) =>
        label.name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setAllProjects(tempSearchData);
    } else {
      setAllProjects(allProjectsDetials);
    }
  };

  //fetch all project's details
  const fetchAllProjectData = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    let engineerId = JSON.parse(
      window.localStorage.getItem("user-attributes")
    ).sub;

    await getAllEngineerProject(engineerId)
      .then(async (res) => {
        Object.entries(res).map(([key, value]) =>
          tempArray.push({
            key: key,
            name: value.name,
            created_at: moment(value.created_at).format("l"),
            companiesAssigned: [],
            document_types:
              value?.document_type_id[0] !== null
                ? value?.document_type_id
                : [],
          })
        );
        await getCompanyProject().then((res) =>
          tempArray.map((item) => {
            res.map((value) => {
              if (item?.key === Object.keys(value.project_id)[0]) {
                item["companiesAssigned"] =
                  Object.values(value.project_id)[0]?.[0] !== null
                    ? Object.values(value.project_id)[0]
                    : [];
                item["company_project_id"] = value?.id;
              }
              return item;
            });
            return item;
          })
        );
        setAllProjects(tempArray);
        setAllProjectsDetials(tempArray);
        dispatch(setLoader(false));
      })

      .catch((err) => {
        message.error("Something went wrong");
        dispatch(setLoader(false));
      });
  };

  const fetchAllCompaniesData = async () => {
    await getAllAdminComapnies()
      .then((res) => setCompaniesData(res))
      .catch((err) => {
        console.error(err);
      });
  };

  // Update project
  const UpdateProject = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    let all = {
      assigned_engineer_id: JSON.parse(
        window.localStorage.getItem("user-attributes")
      ).sub,
      company_id: data.companiesAssigned,
      project_id: selectProjectData?.key,
      document_types: data.document_types,
    };
    await updateCompanyProject(all)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Project.Update"));
          fetchAllProjectData(allEngineers);
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  //fetch all engineer's details
  const fetchAllEngineersData = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAllEngineers()
      .then((res) => {
        res.map((e) =>
          tempArray.push({
            key: e.user_sub,
            name: e.first_name + " " + e.last_name,
          })
        );
        fetchAllCompaniesData();
        setAllEngineers(tempArray);
        //dispatch(setLoader(false));
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  // Fetch all documents type
  const fetchAllDocumentTypes = async () => {
    dispatch(setLoader(true));
    await getAllDocumentTypes()
      .then((response) => {
        setDocumentType(response);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  useEffect(() => {
    fetchAllProjectData();
    fetchAllEngineersData();
    fetchAllDocumentTypes();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Dashboard.Title")}
              </h2>
            </Col>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>
        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              columns={columns}
              dataSource={allProjects}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>
      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {t("Admin.Projects.DrawerUpdateTitle")}
          </span>
        }
        placement="right"
        onClose={closeAddDrawer}
        visible={addDrawer}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={UpdateProject}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Projects.DrawerName")}
              </strong>
            }
            rules={[
              {
                required: true,
                message: t("Error.Admin.Project.P01"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Admin.Project.DrawerName")}
              disabled
            />
          </Form.Item>

          <Form.Item
            name="assigned_engineer_id"
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Projects.DrawerEngineer")}
              </strong>
            }
            rules={[
              {
                required: true,
                message: t("Error.Admin.Project.P02"),
              },
            ]}
          >
            <Select
              placeholder={t("Placeholder.Admin.Project.DrawerEngineer")}
              style={{ width: "100%" }}
              disabled
            >
              {allEngineers.map((item, index) => {
                return (
                  <Option key={index} value={item.key}>
                    {item?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
            name="companiesAssigned"
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Projects.DrawerCompany")}
              </strong>
            }
            rules={[
              {
                required: true,
                message: t("Error.Admin.Project.P03"),
              },
            ]}
          >
            <Select
              placeholder={t("Placeholder.Admin.Project.DrawerCompany")}
              style={{ width: "100%" }}
              mode="multiple"
              filterOption={(input, option) =>
                option.props.children !== null &&
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
            >
              {companiesData.map((item, index) => {
                return (
                  <Option key={index} value={item.company_id}>
                    {item?.company_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="document_types"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Project.P03"),
              },
            ]}
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Projects.DrawerRequiredDocument")}
              </strong>
            }
          >
            <Select
              placeholder={t("Placeholder.Admin.Project.DrawerCompany")}
              mode="multiple"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
            >
              {documentType.map((item, index) => {
                return (
                  <Option key={index} value={item.id}>
                    {item?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              loading={createLoading}
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
            >
              {t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
