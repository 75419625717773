import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Modal,
  Spin,
  message,
} from "antd";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  getReports,
  createReport,
  updateReport,
  deleteReports,
  getEngineerById,
} from "../../../Api/restApi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";

const { confirm } = Modal;
const { Search } = Input;

export default function Reports() {
  const { t } = useTranslation();
  const [editDrawer, setEditDrawer] = useState(false);
  const [reports, setReports] = useState([]);
  const [allReports, setAllReports] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [reportLoaded, setReportLoaded] = useState(false);
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [selectDrawer, setSelectDrawer] = useState(false);
  const [engineerData, serEngineerData] = useState([]);
  const [reportData, setReportData] = useState({});

  const columns = [
    {
      title: t("Engineer.Reports.TC01"),
      dataIndex: "report_name",
      sorter: (a, b) => a.report_name.length - b.report_name.length,
    },
    {
      title: t("Engineer.Reports.TC02"),
      dataIndex: "created_at",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
      render: (data, record) => {
        return <span>{moment(data).format("l")}</span>;
      },
    },
    {
      title: t("Engineer.Reports.TC03"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];
  const showEditDrawer = (record) => {
    form.resetFields();
    setReportData(record);
    setSelectDrawer(false);

    form.setFieldsValue(record);
    setEditDrawer(true);
  };

  const closeEditDrawer = () => {
    setEditDrawer(false);
  };

  const showAddDrawer = () => {
    setSelectDrawer(true);
    form.resetFields();
    setEditDrawer(true);
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //delete company
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(data.map((item) => deleteReports(item)))
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Reports.Delete"));
          setData([]);
          fetchReports(engineerData[0].engineer_id);
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allReports.filter((data) =>
        data.report_name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setReports(tempSearchData);
    } else {
      setReports(allReports);
    }
  };

  const addReport = async () => {
    setReportLoaded(true);
    let data = form.getFieldsValue();
    let allData = {
      report_name: data.report_name,
      engineer_owner_id: engineerData[0].engineer_id,
    };
    await createReport(allData)
      .then((res) => {
        setEditDrawer(false);
        message.success(t("SuccessfullMsg.Engineer.Reports.Create"));
        fetchReports(engineerData[0].engineer_id);
        setReportLoaded(false);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        setReportLoaded(false);
        setEditDrawer(false);
      });
  };
  const UpdateReport = async () => {
    setReportLoaded(true);
    let data = form.getFieldsValue();
    let allData = {
      id: reportData?.key,
      name: data.report_name,
      engineer_owner_id: engineerData[0].engineer_id,
    };
    await updateReport(allData)
      .then((res) => {
        setEditDrawer(false);
        message.success(t("SuccessfullMsg.Engineer.Reports.Update"));
        fetchReports(engineerData[0].engineer_id);
        setReportLoaded(false);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        setReportLoaded(false);
        setEditDrawer(false);
      });
  };

  const fetchReports = async (id) => {
    dispatch(setLoader(true));
    await getReports(id)
      .then((res) => {
        let tempArray = [];
        res.map((item) => {
          tempArray.push({
            report_name: item.report_name,
            created_at: item.created_at,
            name: item.name,
            key: item.id,
          });
          return tempArray;
        });
        setReports(tempArray);
        setAllReports(tempArray);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  const fetchEngineerById = async () => {
    dispatch(setLoader(true));
    let engineerUserSub = JSON.parse(
      window.localStorage.getItem("user-attributes")
    ).sub;
    await getEngineerById(engineerUserSub)
      .then((res) => {
        fetchReports(res[0]?.engineer_id);
        serEngineerData(res);
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.error(err);
      });
  };

  useEffect(() => {
    fetchEngineerById();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Reports.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>

        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={reports}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Engineer.Reports.DrawerTitle")
              : t("Engineer.Reports.DrawerEditTitle")}
          </span>
        }
        placement="right"
        onClose={closeEditDrawer}
        visible={editDrawer}
      >
        <Form
          form={form}
          requiredMark={false}
          onFinish={selectDrawer ? addReport : UpdateReport}
          layout="vertical"
        >
          <Form.Item
            name="report_name"
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Engineer.Reports.DrawerFile")}
              </strong>
            }
            rules={[
              {
                required: true,
                message: t("Error.Engineer.Reports.R01"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Engineer.Reports.DrawerReport")}
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setEditDrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              loading={reportLoaded}
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
