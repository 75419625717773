// import external modules
import React, { Component, Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import MainLayout from "../containers/MainLayout";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class MainLayoutRoute extends Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
  }

  render() {
    const { render, rest, token } = this.props;
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <Fragment>
            {!token && <Redirect from={matchProps.path} to={"/login"} />}

            {token && <MainLayout>{render(matchProps)}</MainLayout>}
          </Fragment>
        )}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { token } = state;
  return {
    prop: state.prop,
    token,
  };
};

export default withRouter(connect(mapStateToProps)(MainLayoutRoute));
