import { Col, Row, Form, Input, Button, message, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import sideImage from "../../../assets/images/login-sidebaner.svg";
import logo from "../../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";
import Footer from "../../../common/Footer";
import { useHistory } from "react-router";
import { Auth } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { setLoader, setToken, setMenuItem } from "../../../store/Actions";
import { getMenuItem } from "../../../Api/restApi";

export default function Login() {
  const { t } = useTranslation();
  let history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state);
  const [userNotConfirmed, setuserNotConfirmed] = useState(false);
  const [tnc, settnc] = useState(false);
  const [verifyButton, setVarifyButton] = useState(false);
  const [verify, setVerify] = useState(true);


  const setitems = async (data) => {
    window.localStorage.setItem(
      "Token",
      data?.signInUserSession?.accessToken?.jwtToken
    );
    window.localStorage.setItem(
      "user-attributes",
      JSON.stringify(data?.attributes)
    );
    dispatch(setToken(data?.signInUserSession?.accessToken?.jwtToken));
  };

  // Function to login submit
  const onSubmit = async (e) => {
    dispatch(setLoader(true));
    let formValue = form.getFieldsValue();
    await Auth.signIn(formValue?.email, formValue?.password)
      .then(async (data) => {
        let { idToken } = await Auth.currentSession();
        dispatch(setLoader(false));
        setitems(data).then(async () => {
          switch (idToken?.["payload"]?.["cognito:groups"]?.[0]) {
            case "engineers":
              dispatch(setLoader(true));
              await getMenuItem("ENGINEER")
                .then((res) => {
                  dispatch(setMenuItem(res));
                  let data1 = res;
                  localStorage.setItem("menu", JSON.stringify(data1));
                  localStorage.setItem("Role", "engineers");
                })
                .catch((err) => console.log(err));
              return history.push("/engineer/projectos");
            case "admin":
              dispatch(setLoader(true));
              await getMenuItem("ADMIN")
                .then((res) => {
                  dispatch(setMenuItem(res));
                  let data1 = res;
                  localStorage.setItem("menu", JSON.stringify(data1));
                  localStorage.setItem("Role", "admin");
                  return history.push("/adm/engenheiros");
                })
                .catch((err) => console.log(err));
              break;

            default:
              return;
          }
        });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
        if (err.code === "UserNotConfirmedException") {
          setuserNotConfirmed(true);
          message.info(t("login.error.ConfirmationCode"));
        } else {
          message.error(err.message);
        }
      });
  };

  // Function to change password
  const changePassword = async () => {
    let formValue = form.getFieldsValue();
    dispatch(setLoader(true));
    await Auth.signIn(formValue?.email, "12345678")
      .then(async (res) => {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            Auth.changePassword(user, "12345678", formValue.password)
              .then((res) => onSubmit())
              .catch((err) => {
                dispatch(setLoader(false));
                console.log(err);
              });
          })
          .catch((err) => {
            dispatch(setLoader(false));
            console.log(err);
          });
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  // Function to verify user
  const VerifyUser = (data) => {
    dispatch(setLoader(true));

    if (data.code) {
      Auth.confirmSignUp(data.email, String(data.code))
        .then((res) => {
          dispatch(setLoader(false));
          setuserNotConfirmed(false);
          setVarifyButton(true);
          message.info(t("login.ConfirmationMsg"));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoader(false));
          if (err.code === "NotAuthorizedException") {
            setuserNotConfirmed(false);
          } else {
            message.error(err.message);
          }
        });
    }
  };

  // Function to verify email
  const VerifyEmail = (data) => {
    dispatch(setLoader(true));

    if (data.code) {
      Auth.confirmSignUp(data.email, String(data.code))
        .then((res) => {
          dispatch(setLoader(false));
          setVarifyButton(true);
          setVerify(true);
          message.info(t("login.ConfirmationMsg"));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setLoader(false));
          if (err.code === "NotAuthorizedException") {
            setuserNotConfirmed(false);
          } else {
            message.error(err.message);
          }
        });
    }
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      window.localStorage.clear();
      history.push("/login");
    }
    if (window.location.pathname === "/engineer") {
      setVerify(false);
    }
    dispatch(setLoader(false));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row style={{ height: "100vh" }}>
        <Col
          span={12}
          lg={12}
          md={0}
          sm={0}
          xs={0}
          style={{
            backgroundImage: `url(${sideImage})`,
            backgroundPosition: "end",
            backgeoundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></Col>

        <Col
          span={12}
          lg={12}
          md={24}
          sm={24}
          xs={24}
          style={{ position: "relative" }}
        >
          <Row
            style={{ height: "100vh", alignItems: "center" }}
            justify="center"
          >
            <Col span={12} lg={12} md={12} sm={24} xs={24}>
              <Row justify="center">
                <img src={logo} style={{ height: 80 }} alt="logo" />
              </Row>
              {!verify && verify !== undefined ? (
                <Form
                  name="login-form"
                  requiredMark={false}
                  layout="vertical"
                  onFinish={VerifyEmail}
                  autoComplete="off"
                  form={form}
                >
                  <Form.Item
                    label="Email ID"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("Error.Admin.Engineer.E03"),
                      },
                      {
                        pattern: /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/,
                        message: t("Error.Admin.Engineer.E04"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("Placeholder.Admin.Engineer.DrawerEmail")}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: t("Error.Verify"),
                      },
                    ]}
                  >
                    <Input placeholder={t("login.Placeholder.VerifyCode")} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      className="primaryBtn"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={isLoading}
                    >
                      {t("login.VerifyBtn")}
                    </Button>
                  </Form.Item>
                </Form>
              ) : !userNotConfirmed ? (
                <Form
                  requiredMark={false}
                  layout="vertical"
                  form={form}
                  onFinish={(e) =>
                    verifyButton ? changePassword(e) : onSubmit(e)
                  }
                >
                  <Form.Item
                    label={
                      <span style={{ color: "#7E7E7E" }}>
                        {t("login.labelEmail")}
                      </span>
                    }
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("Error.Admin.Engineer.E03"),
                      },
                      {
                        pattern: /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/,
                        message: t("Error.Admin.Engineer.E04"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("Placeholder.Admin.Engineer.DrawerEmail")}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ color: "#7E7E7E" }}>
                        {t("login.labelPass")}
                      </span>
                    }
                    name="password"
                    rules={[
                      {
                        min: 8,
                        message: t("ErrorPassword01"),
                      },
                      {
                        required: true,
                        message: t("ErrorPassword02"),
                      },
                    ]}
                  >
                    <Input.Password
                      type={"password"}
                      placeholder={t(
                        "Placeholder.Admin.Engineer.DrawerPassword"
                      )}
                    />
                  </Form.Item>
                  {verifyButton ? (
                    <Form.Item
                      label={
                        <strong style={{ color: "#7E7E7E" }}>
                          {t("Admin.Dashboard.DrawerConfirmPassword")}
                        </strong>
                      }
                      name="leader_confim_password"
                      rules={[
                        {
                          required: true,
                          message: t("Error.Admin.Engineer.E06"),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(t("Error.Admin.Engineer.E07"))
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        type={"password"}
                        placeholder={t(
                          "Placeholder.Admin.Engineer.DrawerConfirmPassword"
                        )}
                      />
                    </Form.Item>
                  ) : null}

                  <Button
                    className="primaryBtn"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={isLoading}
                  >
                    {t("login.loginBtn")}
                  </Button>
                </Form>
              ) : (
                <Form
                  name="login-form"
                  requiredMark={false}
                  layout="vertical"
                  onFinish={VerifyUser}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email ID"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t("Error.Admin.Engineer.E03"),
                      },
                      {
                        pattern: /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/,
                        message: t("Error.Admin.Engineer.E04"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("Placeholder.Admin.Engineer.DrawerEmail")}
                      disabled
                    />
                  </Form.Item>

                  <Form.Item
                    label="code"
                    name="code"
                    rules={[
                      {
                        required: true,
                        message: t("Error.Verify"),
                      },
                    ]}
                  >
                    <Input placeholder={t("login.Placeholder.VerifyCode")} />
                  </Form.Item>

                  <Checkbox
                    value={tnc}
                    style={{ width: "100%" }}
                    onChange={(val) => settnc(val?.target?.checked)}
                  >
                    l agree with the{" "}
                    <a
                      href="https://projagt-20220203165943-hostingbucket-prod.s3.us-east-2.amazonaws.com/rules_and_conditions.pdf"
                      rel="noreferrer"
                      target="_blank"
                    >
                      terms and conditions
                    </a>
                  </Checkbox>

                  <Form.Item>
                    <Button
                      className="primaryBtn"
                      htmlType="submit"
                      style={{ width: "100%" }}
                      loading={isLoading}
                      disabled={!tnc}
                    >
                      {t("login.VerifyBtn")}
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Col>
          </Row>

          <Footer />
        </Col>
      </Row>
    </>
  );
}
