import { API, Storage } from "aws-amplify";

const GetRequest = (url) => API.get("restapi", url);

//get the details of all engineers
export const getAllEngineers = () => {
  let ApiUrl = `/katia/get_all_engineers`;
  return GetRequest(ApiUrl);
};

// create new engineer
export const createEngineer = (inputdata) => {
  let ApiUrl = `/katia/add_engineer`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

// Update engineer
export const updateEngineer = (inputdata) => {
  let ApiUrl = `/katia/update_engineer`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// Delete an engineer
export const deleteEngineer = (id) => {
  let ApiUrl = `/katia/delete_engineer?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Get all projects
export const getAllProjects = () => {
  let ApiUrl = `/katia/get_all_projects`;
  return GetRequest(ApiUrl);
};

// create new project
export const createProject = (inputdata) => {
  let ApiUrl = `/katia/add_project`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

//delete project
export const deleteProject = (id) => {
  let ApiUrl = `/katia/delete_project?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Update engineer
export const updateProject = (inputdata) => {
  let ApiUrl = `/katia/update_project`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// Get all companies for admin side
export const getAllAdminComapnies = () => {
  let ApiUrl = `/katia/get_all_companies`;
  return GetRequest(ApiUrl);
};

// Create a new company for admin side
export const createAdminComapny = (inputdata) => {
  let ApiUrl = `/katia/add_companies`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

//delete company for admin side
export const deleteAdminCompany = (id) => {
  let ApiUrl = `/katia/delete_company?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Update Company for admin side
export const updateAdminCompany = (inputdata) => {
  let ApiUrl = `/katia/update_company`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// Get all companies for engineer side
export const getAllComapnies = () => {
  let ApiUrl = `/engineers/get_all_companies`;
  return GetRequest(ApiUrl);
};

// Create a new company for engineer side
export const createComapny = (inputdata) => {
  let ApiUrl = `/engineers/add_companies`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

//delete company for engineer side
export const deleteCompany = (id) => {
  let ApiUrl = `/engineers/delete_company?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Update Company for engineer side
export const updateCompany = (inputdata) => {
  let ApiUrl = `/engineers/update_company`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// Get all labels
export const getAllLabels = () => {
  //let user_sub = JSON.parse(window.localStorage.getItem('user-attributes')).sub
  let ApiUrl = `/engineers/get_all_labels`;
  return GetRequest(ApiUrl);
};
// Create a new Label
export const createLabel = (inputdata) => {
  let ApiUrl = `/engineers/add_label`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

// Update label
export const updateLabel = (inputdata) => {
  let ApiUrl = `/engineers/update_label`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

//Delete a Label
export const deleteLabel = (id) => {
  let ApiUrl = `/engineers/delete_label?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Get all Documnents from engineer side
export const getAllEnginnerDocuments = () => {
  let user_sub = JSON.parse(window.localStorage.getItem("user-attributes")).sub;
  let ApiUrl = `/engineers/get_all_documents?user_sub=${user_sub}`;
  return GetRequest(ApiUrl);
};

// Create a new Label from engineer side
export const createEngineerDocuments = (inputdata) => {
  let ApiUrl = `/engineers/add_document`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

// Update Document from engineer side
export const updateEngineerDocuments = (inputdata) => {
  let ApiUrl = `/engineers/update_document`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

//Delete a Documetns from engineer side
export const deleteEngineerDocument = (id) => {
  let ApiUrl = `/engineers/delete_document?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Get project from engineer side
export const getAllEngineerProject = (id) => {
  let ApiUrl = `/engineers/get_project?assigned_engineer_id=${id}`;
  return GetRequest(ApiUrl);
};

// Get document_type from engineer side
export const getAllDocumentTypes = () => {
  let ApiUrl = `/engineers/get_all_document_types`;
  return GetRequest(ApiUrl);
};

// Get document_type from admin side
export const getAllAdminDocumentTypes = () => {
  let ApiUrl = `/katia/get_all_document_types`;
  return GetRequest(ApiUrl);
};

// Create a new Label
export const createDocumentType = (inputdata) => {
  let ApiUrl = `/katia/add_document_type`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

// Update Company
export const updateDocumentType = (inputdata) => {
  let ApiUrl = `/katia/update_document_type`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

//Delete a Label
export const deleteDocumentType = (id) => {
  let ApiUrl = `/katia/delete_document_type?id=${id}`;
  return API.del("restapi", ApiUrl);
};

//file upload on s3
export const uploadFileToS3 = async (file, org_id, company_id) => {
  let filename = `${org_id}/${company_id}/${file.name}`;
  return await Storage.put(filename, file, {
    contentType: file.type,
  });
};

// Get file from s3
export const getFileFromS3 = async (key) => {
  return await Storage.get(key);
};

// File remove from s3
export const removeFileToS3 = async (file) => {
  let filename = `${file}`;
  return await Storage.remove(filename, {
    level: "public",
  });
};

// Update project from engineer side
export const updateCompanyProject = async (inputdata) => {
  let ApiUrl = `/engineers/update_company_projects`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// get company project from engineer side
export const getCompanyProject = async () => {
  let ApiUrl = `/engineers/get_company_projects`;
  return GetRequest(ApiUrl);
};

// get company project from admin side
export const getAdminCompanyProject = async () => {
  let ApiUrl = `/katia/get_company_projects`;
  return GetRequest(ApiUrl);
};

// Get equipment from engineer side
export const getEngineerEquipment = async () => {
  let ApiUrl = `/engineers/get_all_equipments`;
  return GetRequest(ApiUrl);
};

// Get equipment from admin side

export const getAdminEquipmentType = async () => {
  let ApiUrl = `/katia/get_all_equipment_types`;
  return GetRequest(ApiUrl);
};

// Create equipmentType
export const createEquipmentType = async (inputdata) => {
  let ApiUrl = `/katia/add_equipment_type`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

export const updateEquipmentType = async (inputdata) => {
  let ApiUrl = `/katia/update_equipment_type`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// delete equipment
export const deleteEquipmentType = async (id) => {
  let ApiUrl = `/katia/delete_equipment_type?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Get equipment from admin side
export const getAdminEquipment = async () => {
  let ApiUrl = `/katia/get_all_equipments`;
  return GetRequest(ApiUrl);
};

// Create equipment
export const createEquipment = async (inputdata) => {
  let ApiUrl = `/katia/add_equipment`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

export const updateEquipment = async (inputdata) => {
  let ApiUrl = `/katia/update_equipment`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// delete equipment
export const deleteEquipment = async (id) => {
  let ApiUrl = `/katia/delete_equipment?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Get all admin documents
export const getAllDocuments = () => {
  let ApiUrl = `/katia/get_all_documents`;
  return GetRequest(ApiUrl);
};

// Create a new documents
export const createDocument = (inputdata) => {
  let ApiUrl = `/katia/add_document`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

// Update admin document
export const updateDocument = (inputdata) => {
  let ApiUrl = `/katia/update_document`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

//Delete admin document
export const deleteDocument = (id) => {
  let ApiUrl = `/katia/delete_document?id=${id}`;
  return API.del("restapi", ApiUrl);
};

// Get Reports from engineer
export const getReports = async (id) => {
  let ApiUrl = `/engineers/get_all_reports?id=${id}`;
  return GetRequest(ApiUrl);
};

// Create report from engineer side
export const createReport = (inputdata) => {
  let ApiUrl = `/engineers/add_report`;
  let data = {
    body: inputdata,
  };
  return API.post("restapi", ApiUrl, data);
};

// get Engineer by id from enigeer side
export const getEngineerById = async (id) => {
  let ApiUrl = `/engineers/get_engineer?id=${id}`;
  return GetRequest(ApiUrl);
};

// Edit report from engineer side

export const updateReport = async (inputdata) => {
  let ApiUrl = `/engineers/update_report`;
  let data = {
    body: inputdata,
  };
  return API.put("restapi", ApiUrl, data);
};

// delete report from engineer side
export const deleteReports = (id) => {
  let ApiUrl = `/engineers/delete_report?id=${id}`;
  return API.del("restapi", ApiUrl);
};

//Get menu item
export const getMenuItem = (side) => {
  let ApiUrl = `/katia/get_menu_items?role=${side}`;
  return GetRequest(ApiUrl);
};

// Get all engineer's projects from admin side
export const getAdminAllEngineerProjects = () =>{
    let ApiUrl = `/katia/get_engineer_projects`;
    return GetRequest(ApiUrl); 
}
