export const setLoginData = (data) => {
  return {
    type: "SET_LOGIN_DATA",
    payload: data,
  };
};
export const setLoader = (data) => {
  return {
    type: "SET_LOADER",
    payload: data,
  };
};
export const setToken = (data) => {
  return {
    type: "SET_TOKEN",
    payload: data,
  };
};

export const setLanguageJSON = (data) => {
  return {
    type: "SET_LANGUAGE_JSON",
    payload: data,
  };
};

export const setFirstTime = (data) => {
  return {
    type: "SET_FIRST_TIME",
    payload: data,
  };
};

export const setMenuItem = (data) => {
  return {
    type: "SET_MENU_ITEM",
    payload: data,
  };
};
