import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  getAllLabels,
  getAllComapnies,
  createLabel,
  updateLabel,
  deleteLabel,
  getAllDocumentTypes,
  uploadFileToS3,
  removeFileToS3,
} from "../../../Api/restApi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import { Storage } from "aws-amplify";

const { confirm } = Modal;
const { Option } = Select;
const { Search } = Input;

export default function Labeling() {
  const { t } = useTranslation();
  const [editDrawer, setEditDrawer] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const [labelForm] = Form.useForm();
  const [labelAddLoader, setLabelAddLoader] = useState(false);
  const { isLoading } = useSelector((state) => state);
  const [selectLabelId, setSelectLabelId] = useState("");
  const [selectDrawer, setSelectDrawer] = useState(true);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [allLabelData, setAllLabelData] = useState([]);
  const [fileList, setFileList] = useState({});
  const [labelIdData, setLabelIdData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: t("Engineer.Labelling.TC01"),
      dataIndex: "file_name",
      sorter: (a, b) => a.file_name.length - b.file_name.length,
      render: (data, record) => (
        <Button
          style={{ border: "none", color: "#1890FF" }}
          onClick={() => showModal(record)}
        >
          {data}
        </Button>
      ),
    },
    {
      title: t("Engineer.Labelling.TC02"),
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name.length - b.company_name.length,
    },
    {
      title: t("Engineer.Labelling.TC03"),
      dataIndex: "created_at",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
    },
    {
      title: t("Engineer.Labelling.TC04"),
      dataIndex: "document_name",
      sorter: (a, b) => a.document_name.length - b.document_name.length,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  const showAddDrawer = () => {
    setSelectDrawer(true);
    labelForm.resetFields();
    setEditDrawer(true);
  };

  const showEditDrawer = (record) => {
    labelForm.resetFields();
    setLabelIdData(record);
    setSelectLabelId(record.key);
    setSelectDrawer(false);
    setFileList({});
    labelForm.setFieldsValue(record);
    setEditDrawer(true);
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  const closeEditDrawer = () => {
    setEditDrawer(false);
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Fetch all label's details
  const fetchAllLabels = async (document_type) => {
    dispatch(setLoader(true));
    let allLabels;
    await getAllLabels()
      .then((res) => {
        allLabels = res;
        getAllComapnies().then((res) => {
          setCompaniesData(res);
          let tempArray = [];

          res.map((e) => {
            allLabels.map(async (data) => {
              if (data.company_id === e.company_id) {
                tempArray.push({
                  key: data.id,
                  company_id: data.company_id,
                  company_name: e.company_name,
                  file_name: data.file_name.split("/")[2],
                  file_path: data.file_name,
                  created_at: moment(data.created_at).format("l"),
                  document_name: document_type.filter(
                    (item) => item.id === data.document_type
                  )[0].name,
                  document_type: data.document_type,
                });
              }
              return tempArray;
            });

            return tempArray;
          });
          Promise.all(
            tempArray.map((item) => {
              return gets3link(item.file_path);
            })
          )
            .then((data) =>
              data.map((item, index) => {
                return (tempArray[index]["file_link"] = item);
              })
            )
            .catch((err) => {
              console.log(err);
            });
          dispatch(setLoader(false));
          setAllLabelData(tempArray);
          setLabelData(tempArray);
        });
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(true));
      });
  };

  const gets3link = (key) => {
    return Storage.get(key);
  };

  // Fetch all documents type
  const fetchAllDocumentTypes = async () => {
    dispatch(setLoader(true));
    await getAllDocumentTypes()
      .then((response) => {
        setDocumentType(response);
        fetchAllLabels(response);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  // Create a new label
  const addLabel = async () => {
    setLabelAddLoader(true);
    let form = labelForm.getFieldsValue();
    let org_id = JSON.parse(window.localStorage.getItem("user-attributes")).sub;
    let company_id = form.company_id;

    await uploadFileToS3(fileList, org_id, company_id)
      .then(async (response) => {
        let tempInput = {
          file_name: response.key,
          company_id: form.company_id,
          document_type: form.document_type,
          inserted_by_engineer_id: JSON.parse(
            window.localStorage.getItem("user-attributes")
          ).sub,
          manual_label: 1,
        };
        await createLabel(tempInput)
          .then((res) => {
            setLabelAddLoader(false);
            //setEditDrawer(false);
            if (res.message === "Success") {
              message.success(t("SuccessfullMsg.Engineer.Labelling.Create"));
              fetchAllLabels(documentType);
            }
          })
          .catch((err) => {
            setLabelAddLoader(false);
            setEditDrawer(false);
            message.error(t("SomthingWentWrong"));
          });
      })
      .catch((err) => {
        setLabelAddLoader(false);
        console.log(err);
        message.error("Error while uploading file");
      });
  };

  // Update Label
  const UpdateLabel = async () => {
    setLabelAddLoader(true);
    let form = labelForm.getFieldsValue();
    if (fileList?.name) {
      await Storage.remove(labelIdData?.file_path).then(async (res) => {
        let org_id = JSON.parse(
          window.localStorage.getItem("user-attributes")
        ).sub;
        let company_id = form.company_id;

        await uploadFileToS3(fileList, org_id, company_id)
          .then(async (response) => {
            let tempInput = {
              id: selectLabelId,
              data: {
                file_name: response.key,
                company_id: form.company_id,
                document_type: form.document_type,
                inserted_by_engineer_id: JSON.parse(
                  window.localStorage.getItem("user-attributes")
                ).sub,
              },
            };
            await updateLabel(tempInput)
              .then((res) => {
                setLabelAddLoader(false);
                if (res.message === "Success") {
                  message.success(
                    t("SuccessfullMsg.Engineer.Labelling.Update")
                  );
                  fetchAllLabels(documentType);
                }
              })
              .catch((err) => {
                setLabelAddLoader(false);
                setEditDrawer(false);
                message.error(t("SomthingWentWrong"));
              });
          })
          .catch((err) => {
            setLabelAddLoader(false);
            console.log(err);
            message.error("Error while uploading file");
          });
      });
    } else {
      let tempInput = {
        id: selectLabelId,
        data: {
          file_name: labelIdData?.file_path,
          company_id: form.company_id,
          document_type: form.document_type,
        },
      };
      await updateLabel(tempInput)
        .then((res) => {
          setLabelAddLoader(false);
          setEditDrawer(false);
          if (res.message === "Success") {
            message.success(t("SuccessfullMsg.Engineer.Labelling.Update"));
            fetchAllLabels(documentType);
          }
        })
        .catch((err) => {
          setLabelAddLoader(false);
          setEditDrawer(false);
          message.error(t("SomthingWentWrong"));
        });
    }
  };

  // Delete label
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(
      data.map((item) => {
        RemoveFileToS3(item);
        return deleteLabel(item);
      })
    )
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Labelling.Delete"));
          setData([]);
          fetchAllLabels(documentType);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
      });
  };

  const RemoveFileToS3 = async (item) => {
    let removeData = labelData.filter((value) => value.key === item);
    let file = removeData[0]?.file_path;
    await removeFileToS3(file)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allLabelData.filter((label) =>
        label.file_name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setLabelData(tempSearchData);
    } else {
      setLabelData(allLabelData);
    }
  };

  const showModal = (record) => {
    setLabelIdData(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchAllDocumentTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Labelling.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={labelData}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Engineer.Labelling.DrawerTitle")
              : t("Engineer.Labelling.DrawerEditTitle")}
          </span>
        }
        placement="right"
        onClose={closeEditDrawer}
        visible={editDrawer}
      >
        {selectDrawer ? (
          <Form
            form={labelForm}
            onFinish={selectDrawer ? addLabel : UpdateLabel}
            requiredMark={false}
            layout="vertical"
          >
            <Form.Item
              name="file_name"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Labelling.DrawerFile")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Lableing.L01"),
                },
              ]}
            >
              <Input
                type="file"
                onChange={(e) => setFileList(e.target.files[0])}
              />
            </Form.Item>

            <Form.Item
              name="document_type"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Labelling.DrawerLabel")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Lableing.L02"),
                },
              ]}
            >
              <Select
                placeholder={t("Placeholder.Engineer.Labelling.DrawerLabel")}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                  option.props.children !== null && option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
              >
                {documentType !== null && documentType.length > 0
                  ? documentType.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item?.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              name="company_id"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Labelling.DrawerCompany")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Lableing.L03"),
                },
              ]}
            >
              <Select
                placeholder={t("Placeholder.Engineer.Labelling.DrawerCompany")}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children !== null && option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                showSearch
              >
                {companiesData !== null && companiesData.length > 0
                  ? companiesData.map((item, index) => {
                      return (
                        <Option key={index} value={item.company_id}>
                          {item?.company_name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "left",
                display: "flex",
              }}
            >
              <Button
                className="secondary-btn"
                onClick={() => setEditDrawer(false)}
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  marginRight: 8,
                  height: "40px",
                  background: "transparent",
                  borderColor: "#00A8CC",
                  color: "#00A8CC",
                }}
              >
                {t("Cancle")}
              </Button>
              <Button
                htmlType="submit"
                loading={labelAddLoader}
                type="primary"
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  height: "40px",
                  background: "#00A8CC",
                  borderColor: "#00A8CC",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                {selectDrawer ? t("Create") : t("Update")}
              </Button>
            </div>
          </Form>
        ) : (
          <Form
            form={labelForm}
            onFinish={selectDrawer ? addLabel : UpdateLabel}
            requiredMark={false}
            layout="vertical"
          >
            <Form.Item
              name="file_name"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Labelling.DrawerFile")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Lableing.L01"),
                },
              ]}
            >
              <Input
                type="file"
                onChange={(e) => setFileList(e.target.files[0])}
              />
              {!selectDrawer
                ? fileList?.name
                  ? null
                  : labelIdData?.file_name
                : null}
            </Form.Item>

            <Form.Item
              name="document_type"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Labelling.DrawerLabel")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Lableing.L02"),
                },
              ]}
            >
              <Select
                placeholder={t("Placeholder.Engineer.Labelling.DrawerLabel")}
                style={{ width: "100%" }}
                showSearch
              filterOption={(input, option) =>
                option.props.children !== null && option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              >
                {documentType !== null && documentType.length > 0
                  ? documentType.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item?.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <Form.Item
              name="company_id"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Labelling.DrawerCompany")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Lableing.L03"),
                },
              ]}
            >
              <Select
                placeholder={t("Placeholder.Engineer.Labelling.DrawerCompany")}
                style={{ width: "100%" }}
                showSearch
                filterOption={(input, option) =>
                option.props.children !== null &&
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
              >
                {companiesData !== null && companiesData.length > 0
                  ? companiesData.map((item, index) => {
                      return (
                        <Option key={index} value={item.company_id}>
                          {item?.company_name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "left",
                display: "flex",
              }}
            >
              <Button
                className="secondary-btn"
                onClick={() => setEditDrawer(false)}
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  marginRight: 8,
                  height: "40px",
                  background: "transparent",
                  borderColor: "#00A8CC",
                  color: "#00A8CC",
                }}
              >
                {t("Cancle")}
              </Button>
              <Button
                htmlType="submit"
                loading={labelAddLoader}
                type="primary"
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  height: "40px",
                  background: "#00A8CC",
                  borderColor: "#00A8CC",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                {selectDrawer ? t("Create") : t("Update")}
              </Button>
            </div>
          </Form>
        )}
      </Drawer>

      <Modal
        title={labelIdData?.file_name}
        footer=""
        visible={isModalVisible}
        style={{ width: "35%" }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe
          id="frame1"
          src={labelIdData?.file_link}
          title="myFrame"
          style={{ height: "500px" }}
          scrolling="no"
          width="100%"
          frameborder="0"
        ></iframe>
      </Modal>
    </>
  );
}
