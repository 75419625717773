import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import language from '../assets/translations/language.json'
import HttpApi from "i18next-http-backend";

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    // resources:{
    //     en: { translation:  language},
    // },
    backend: {
      loadPath:
        window.location.hostname === "localhost"
          ? "https://katia-20220601190318-hostingbucket-dev.s3.us-east-2.amazonaws.com/public_static/language.json"
          : `${window.location.origin}/public_static/language.json`,
      crossDomain: true,
      allowMultiLoading: true,
    },
    lng: "en", //default language
    fallbackLng: "en", //when specified language translations not present

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
