import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Select,
  Modal,
  Spin,
  message,
} from "antd";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  getAllAdminDocumentTypes,
  getAdminEquipmentType,
  deleteEquipmentType,
  createEquipmentType,
  updateEquipmentType,
  createDocumentType,
  updateDocumentType,
  deleteDocumentType,
} from "../../../Api/restApi";
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "../../../store/Actions";
const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;

export default function Configuration() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const isLoading = useSelector((state) => state.isLoading);
  const [documentType, setDocumentType] = useState([]);
  const [equipmentType, setEquipmentType] = useState([]);
  const [allDocumentType, setAllDocumentType] = useState([]);
  const [allEquipmentType, setAllEquipmentType] = useState([]);
  const [screen, setScreen] = useState();
  const [formData, setFormData] = useState({});
  const [data, setData] = useState([]);
  const [equipmentData, setEquipmantData] = useState([]);
  const [createLoading, setIsCreateLoading] = useState(false);
  const [selectDrawer, setSelectDrawer] = useState(true);
  const [editDrawer, setEditDrawer] = useState(false);
  const [Loading, setLoading] = useState(false);

  const YESNO = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  const columns = [
    {
      title: t("Admin.Configuration.DocumentType.TC01"),
      dataIndex: "category",
      sorter: (a, b) => a.category.length - b.category.length,
    },
    {
      title: t("Admin.Configuration.DocumentType.TC02"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Admin.Configuration.DocumentType.TC03"),
      dataIndex: "has_pii_label",
      sorter: (a, b) => a.has_pii.length - b.has_pii.length,
    },
    {
      title: t("Admin.Configuration.DocumentType.TC04"),
      dataIndex: "needs_external_label",
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record, "Document Type")}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  const equipmentColumn = [
    {
      title: t("Admin.Configuration.EquipmentType.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record, "Equipment")}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  const showEditDrawer = (record, tabelScreen) => {
    form.resetFields();
    setFormData(record);
    setScreen(tabelScreen);
    form.setFieldsValue(record);
    setEditDrawer(true);
    setSelectDrawer(false);
  };

  // Open new drawer
  const showAddDrawer = (tabelScreen) => {
    setScreen(tabelScreen);
    form.resetFields();
    setEditDrawer(true);
    setSelectDrawer(true);
  };

  const closeEditDrawer = () => {
    setEditDrawer(false);
  };

  const showConfirm = (tabelScreen) => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete(tabelScreen);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //Function to handle search
  const handleSearch = (sreachValue, tabelScreen) => {
    if (tabelScreen === "Equipment") {
      if (sreachValue !== "") {
        let tempSearchData = allEquipmentType.filter((item) =>
          item.name.toLowerCase().includes(sreachValue.toLowerCase()) 
        );
        setEquipmentType(tempSearchData);
      } else {
        setEquipmentType(allEquipmentType);
      }
    } else {
      if (sreachValue !== "") {
        let tempSearchData = allDocumentType.filter((item) =>
          item.category.toLowerCase().includes(sreachValue.toLowerCase()) || item.name.toLowerCase().includes(sreachValue.toLowerCase())
        );
        setDocumentType(tempSearchData);
      } else {
        setDocumentType(allDocumentType);
      }
    }
  };

  //delete Equipmant
  const handleDelete = async (tabelScreen) => {
    setLoading(true);
    if (tabelScreen === "Equipment") {
      await Promise.all(equipmentData.map((item) => deleteEquipmentType(item)))
        .then((res) => {
          message.success(
            t("SuccessfullMsg.Admin.Configuration.EquipmentType.Delete")
          );
          setEquipmantData([]);
          fetchEquipmentType();
        })
        .catch((err) => {
          dispatch(setLoader(false));
          console.log(err);
        });
    } else {
      await Promise.all(data.map((item) => deleteDocumentType(item)))
        .then((res) => {
          message.success(
            t("SuccessfullMsg.Admin.Configuration.DocumentType.Delete")
          );
          setData([]);
          fetchDocumentTypes();
        })
        .catch((err) => {
          dispatch(setLoader(false));
          console.log(err);
        });
    }
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setEquipmantData(data);
    },
  };

  // selecting checkbox
  const documentRowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  // Create a new equipment
  const addForm = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    if (screen === "Equipment") {
      await createEquipmentType(data)
        .then((res) => {
          setEditDrawer(false);
          setIsCreateLoading(false);
          if (res.message === "Success") {
            message.success(
              t("SuccessfullMsg.Admin.Configuration.EquipmentType.Create")
            );
            fetchEquipmentType();
          }
        })
        .catch((err) => {
          setIsCreateLoading(false);
          setEditDrawer(false);
          message.error(t("SomthingWentWrong"));
        });
    } else {
      await createDocumentType(data)
        .then((res) => {
          setEditDrawer(false);
          setIsCreateLoading(false);
          if (res.message === "Success") {
            message.success(
              t("SuccessfullMsg.Admin.Configuration.DocumentType.Create")
            );
            fetchDocumentTypes();
          }
        })
        .catch((err) => {
          setIsCreateLoading(false);
          setEditDrawer(false);
          message.error(t("SomthingWentWrong"));
        });
    }
  };

  // Update equipment
  const updateForm = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    if (screen === "Equipment") {
      let all = {
        id: formData?.key,
        name: data.name,
      };
      await updateEquipmentType(all)
        .then((res) => {
          setEditDrawer(false);
          setIsCreateLoading(false);
          if (res.message === "Success") {
            message.success(
              t("SuccessfullMsg.Admin.Configuration.EquipmentType.Update")
            );
            fetchEquipmentType();
          }
        })
        .catch((err) => {
          setIsCreateLoading(false);
          setEditDrawer(false);
          message.error(t("SomthingWentWrong"));
        });
    } else {
      let all = {
        id: formData?.key,
        name: data.name,
        category: data.category,
        has_pii: data.has_pii,
        needs_external: data.needs_external,
      };
      await updateDocumentType(all)
        .then((res) => {
          setEditDrawer(false);
          setIsCreateLoading(false);
          if (res.message === "Success") {
            message.success(
              t("SuccessfullMsg.Admin.Configuration.DocumentType.Update")
            );
            fetchDocumentTypes();
          }
        })
        .catch((err) => {
          setIsCreateLoading(false);
          setEditDrawer(false);
          message.error(t("SomthingWentWrong"));
        });
    }
  };

  // Function to fetch document types
  const fetchDocumentTypes = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAllAdminDocumentTypes()
      .then((response) => {
        response.map((item) => {
          tempArray.push({
            key: item?.id,
            category: item.category,
            name: item.name,
            has_pii: item.has_pii ? true : false,
            has_pii_label: item.has_pii ? "Yes" : "No",
            needs_external:
              item.needs_external !== null
                ? item.needs_external
                  ? true
                  : false
                : null,
            needs_external_label:
              item.needs_external !== null
                ? item.needs_external
                  ? "Yes"
                  : "No"
                : "-",
          });
          return tempArray;
        });
        setAllDocumentType(tempArray);
        setDocumentType(tempArray);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Function to fetch equipment types
  const fetchEquipmentType = async () => {
    setLoading(true);
    await getAdminEquipmentType()
      .then((response) => {
        let tempArray = [];
        response.map((item) => {
          tempArray.push({
            key: item?.id,
            name: item?.name,
          });
          return tempArray;
        });
        setAllEquipmentType(tempArray);
        setEquipmentType(tempArray);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  useEffect(() => {
    fetchDocumentTypes();
    fetchEquipmentType();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Admin.Configuration.DocumentType.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value, "Document")}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={() => showAddDrawer("Document")}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={() => showConfirm("Document")}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>

        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={documentRowSelection}
              columns={columns}
              dataSource={documentType}
              pagination={{ defaultPageSize: 5 }}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <div style={{ borderRadius: 7, background: "white", marginTop: 10 }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Admin.Configuration.EquipmentType.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value, "Equipment")}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={() => showAddDrawer("Equipment")}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={() => showConfirm("Equipment")}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={equipmentData.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>

        <Spin spinning={Loading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={equipmentColumn}
              dataSource={equipmentType}
              bordered
              pagination={{ defaultPageSize: 5 }}
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? screen === "Equipment"
                ? t("Admin.Configuration.EquipmentType.DrawerTitle")
                : t("Admin.Configuration.DocumentType.DrawerTitle")
              : screen === "Equipment"
              ? t("Admin.Configuration.EquipmentType.EditDrawerTitle")
              : t("Admin.Configuration.DocumentType.EditDrawerTitle")}
          </span>
        }
        placement="right"
        onClose={closeEditDrawer}
        visible={editDrawer}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={selectDrawer ? addForm : updateForm}
          requiredMark={false}
        >
          {screen === "Equipment" ? (
            <Form.Item
              name="name"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Admin.Configuration.EquipmentType.DrawerType")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Admin.Configuration.EquipmentType.E01"),
                },
              ]}
            >
              <Input
                placeholder={t(
                  "Placeholder.Admin.Configuration.EquipmentType.DrawerType"
                )}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item
                name="category"
                label={
                  <strong style={{ color: "#7E7E7E" }}>
                    {t("Admin.Configuration.DocumentType.DrawerCategory")}
                  </strong>
                }
                rules={[
                  {
                    required: true,
                    message: t("Error.Admin.Configuration.DocumentType.D01"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "Placeholder.Admin.Configuration.DocumentType.DrawerCategory"
                  )}
                />
              </Form.Item>

              <Form.Item
                name="name"
                label={
                  <strong style={{ color: "#7E7E7E" }}>
                    {t("Admin.Configuration.DocumentType.DrawerDocument")}
                  </strong>
                }
                rules={[
                  {
                    required: true,
                    message: t("Error.Admin.Configuration.DocumentType.D02"),
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "Placeholder.Admin.Configuration.DocumentType.DrawerDocument"
                  )}
                />
              </Form.Item>
              <Form.Item
                name="has_pii"
                label={
                  <strong style={{ color: "#7E7E7E" }}>
                    {t("Admin.Configuration.DocumentType.DrawerHasPII")}
                  </strong>
                }
                rules={[
                  {
                    required: true,
                    message: t("Error.Admin.Configuration.DocumentType.D01"),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={t(
                    "Placeholder.Admin.Equipment.DrawerEquipmentType"
                  )}
                >
                  {YESNO.map((item) => {
                    return <Option value={item?.value}>{item?.label}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="needs_external"
                label={
                  <strong style={{ color: "#7E7E7E" }}>
                    {t("Admin.Configuration.DocumentType.DrawerNeedExternal")}
                  </strong>
                }
                rules={[
                  {
                    required: true,
                    message: t("Error.Admin.Configuration.DocumentType.D01"),
                  },
                ]}
              >
                <Select
                  style={{ width: "100%" }}
                  placeholder={t(
                    "Placeholder.Admin.Equipment.DrawerEquipmentType"
                  )}
                >
                  {YESNO.map((item) => {
                    return <Option value={item?.value}>{item?.label}</Option>;
                  })}
                </Select>
              </Form.Item>
            </>
          )}

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setEditDrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
              loading={createLoading}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
