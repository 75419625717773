import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Table, Input, message, Spin } from "antd";
import { getEngineerEquipment } from "../../../Api/restApi";
import moment from "moment";
import { setLoader } from "../../../store/Actions";
import { useDispatch, useSelector } from "react-redux";
const { Search } = Input;

export default function Equipment() {
  const { t } = useTranslation();
  const [allEquipment, setAllEquipment] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();

  const columns = [
    {
      title: t("Engineer.Equipment.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Engineer.Equipment.TC02"),
      dataIndex: "identifier",
      sorter: (a, b) => a.identifier.length - b.identifier.length,
    },
    {
      title: t("Engineer.Equipment.TC03"),
      dataIndex: "createdAt",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
      render: (data, record) => {
        return <span>{moment(data).format("l")}</span>;
      },
    },
  ];

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allEquipment.filter((label) =>
        label.name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setEquipment(tempSearchData);
    } else {
      setEquipment(allEquipment);
    }
  };

  const fetchEquipment = async () => {
    dispatch(setLoader(true));

    await getEngineerEquipment()
      .then((res) => {
        setEquipment(res);
        setAllEquipment(res);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        message.error(t("SomthingWentWrong"));
      });
  };

  useEffect(() => {
    fetchEquipment();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Equipment.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
              </Row>
            </Col>
          </Row>
        </div>

        <div style={{ padding: "25px" }}>
          <Spin spinning={isLoading}>
            <Table
              locale={{ emptyText: t("NoData") }}
              columns={columns}
              dataSource={equipment}
              bordered
              className="document-table"
            />
          </Spin>
        </div>
      </div>
    </>
  );
}
