import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  Row,
  Table,
  Input,
  message,
  Spin,
  Button,
  Form,
  Drawer,
  Modal,
  Select,
} from "antd";
import {
  getAdminEquipment,
  getAdminEquipmentType,
  createEquipment,
  updateEquipment,
  deleteEquipment,
} from "../../../Api/restApi";
import moment from "moment";
import { setLoader } from "../../../store/Actions";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

export default function Equipment() {
  const { t } = useTranslation();
  const [addDrawer, setAddrawer] = useState(false);
  const [allEquipment, setAllEquipment] = useState([]);
  const [equipmentType, setEquipmentType] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [selectDrawer, setSelectDrawer] = useState(true);
  const [createLoading, setIsCreateLoading] = useState(false);
  const [selectCompanyId, setSelectCompanyId] = useState("");

  const columns = [
    {
      title: t("Admin.Equipment.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Admin.Equipment.TC02"),
      dataIndex: "identifier",
      sorter: (a, b) => a.identifier.length - b.identifier.length,
    },
    {
      title: t("Admin.Equipment.TC03"),
      dataIndex: "createdAt",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
      render: (data, record) => {
        return <span>{moment(data).format("l")}</span>;
      },
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  // Open new drawer
  const showAddDrawer = () => {
    form.resetFields();
    setAddrawer(true);
    setSelectDrawer(true);
  };

  // Close drawer
  const closeAddDrawer = () => {
    setAddrawer(false);
  };

  const showEditDrawer = (record) => {
    setSelectCompanyId(record.key);
    setSelectDrawer(false);
    form.setFieldsValue(record);
    setAddrawer(true);
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      console.log(data);
      setData(data);
    },
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //delete Equipmant
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(data.map((item) => deleteEquipment(item)))
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Equipment.Delete"));
          setData([]);
          fetchEquipment();
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  // Create a new equipment
  const addEquipment = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();

    let allData = {
      identifier: data?.identifier,
      equipment_type_id: data?.equipment_type_id,
    };
    await createEquipment(allData)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Equipment.Create"));
          fetchEquipment();
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Update equipment
  const UpdateEquipment = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    let all = {
      id: selectCompanyId,
      identifier: data?.identifier,
      equipment_type_id: data?.equipment_type_id,
    };
    await updateEquipment(all)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Equipment.Update"));
          fetchEquipment();
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allEquipment.filter((label) =>
        label.name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setEquipment(tempSearchData);
    } else {
      setEquipment(allEquipment);
    }
  };

  const fetchEquipment = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAdminEquipment()
      .then((res) => {
        res.map((item) => {
          tempArray.push({
            key: item?.id,
            name: item?.name,
            identifier: item?.identifier,
            equipment_type_id: item?.equipment_type_id,
          });
          return tempArray;
        });
        setEquipment(tempArray);
        setAllEquipment(tempArray);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        message.error(t("SomthingWentWrong"));
      });
  };

  const fetchEquipmentType = async () => {
    await getAdminEquipmentType()
      .then((res) => {
        setEquipmentType(res);
      })
      .catch((err) => message.error(t("SomthingWentWrong")));
  };

  useEffect(() => {
    fetchEquipment();
    fetchEquipmentType();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Admin.Equipment.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>

        <div style={{ padding: "25px" }}>
          <Spin spinning={isLoading}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={equipment}
              bordered
              className="document-table"
            />
          </Spin>
        </div>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Admin.Equipment.AddDrawerTitle")
              : t("Admin.Equipment.EditDrawerTitle")}
          </span>
        }
        placement="right"
        onClose={closeAddDrawer}
        visible={addDrawer}
      >
        <Form
          form={form}
          onFinish={selectDrawer ? addEquipment : UpdateEquipment}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Equipment.EquipmentName")}
              </strong>
            }
            name="identifier"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Equipment.E01"),
              },
            ]}
          >
            <Input placeholder={t("Placeholder.Admin.Equipment.DrawerName")} />
          </Form.Item>
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Equipment.EquipmentType")}
              </strong>
            }
            name="equipment_type_id"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Equipment.E02"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={t("Placeholder.Admin.Equipment.DrawerEquipmentType")}
            >
              {equipmentType.map((item) => {
                return <Option value={item?.id}>{item?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
              loading={createLoading}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
