import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// admin routes
import AdminDashboard from "../Pages/Admin/Dashboard/Index";
import Documents from "../Pages/Admin/Documents/Index";
import Companies from "../Pages/Admin/Companies/Index";
import Equipment from "../Pages/Admin/Equipment/Index";
import Projects from "../Pages/Admin/Projects/Index";
import Configuration from "../Pages/Admin/Configuration/Index";

// company representative routes
import CompanyRepresentativeDashboard from "../Pages/CompanyRepresentative/Dashboard/Index";
import CompanyRepresentativeEquipment from "../Pages/CompanyRepresentative/Equipment/Index";
import CompanyRepresentativeEmployees from "../Pages/CompanyRepresentative/Employees/Index";
import CompanyRepresentativeDocuments from "../Pages/CompanyRepresentative/Documents/Index";

// engineer routes
import EngineerDashboard from "../Pages/Engineer/Dashboard/Index";
import EngineerCompanies from "../Pages/Engineer/Companies/Index";
import EngineerEquipment from "../Pages/Engineer/Equipment/Index";
import EngineerDocuments from "../Pages/Engineer/Documents/Index";
import EngineerReports from "../Pages/Engineer/Reports/Index";
import EngineerLableing from "../Pages/Engineer/Lableing/Index";

import Login from "../Pages/User/Login/Index";
import MainLayoutRoute from "./MainLayoutRoute";
import { createBrowserHistory } from "history";

export default function Routes() {
  return (
    <BrowserRouter history={createBrowserHistory()}>
      <Switch>
        <Route exact path={"/login"}>
          <Login />
        </Route>

        <Route exact path={"/engineer"}>
          <Login />
        </Route>

        <Route exact path={"/admin/login"}>
          <Login />
        </Route>

        <Route exact path={"/"}>
          <Login />
        </Route>

        {/* admin routes  */}
        <MainLayoutRoute
          exact
          path={"/adm/companhias"}
          render={(matchprops) => <Companies {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/adm/documentos"}
          render={(matchprops) => <Documents {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/adm/equipamentos"}
          render={(matchprops) => <Equipment {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/adm/projectos"}
          render={(matchprops) => <Projects {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/adm/engenheiros"}
          render={(matchprops) => <AdminDashboard {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/adm/Configuração"}
          render={(matchprops) => <Configuration {...matchprops} />}
        />

        {/* company-representative routes  */}
        <MainLayoutRoute
          exact
          path={"/company-representative"}
          render={(matchprops) => (
            <CompanyRepresentativeDashboard {...matchprops} />
          )}
        />

        <MainLayoutRoute
          exact
          path={"/company-representative/equipment"}
          render={(matchprops) => (
            <CompanyRepresentativeEquipment {...matchprops} />
          )}
        />

        <MainLayoutRoute
          exact
          path={"/company-representative/employees"}
          render={(matchprops) => (
            <CompanyRepresentativeEmployees {...matchprops} />
          )}
        />

        <MainLayoutRoute
          exact
          path={"/company-representative/documents"}
          render={(matchprops) => (
            <CompanyRepresentativeDocuments {...matchprops} />
          )}
        />

        {/* engineer router */}
        <MainLayoutRoute
          exact
          path={"/engineer/projectos"}
          render={(matchprops) => <EngineerDashboard {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/engineer/companhias"}
          render={(matchprops) => <EngineerCompanies {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/engineer/equipamentos"}
          render={(matchprops) => <EngineerEquipment {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/engineer/documentos"}
          render={(matchprops) => <EngineerDocuments {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/engineer/relatorios"}
          render={(matchprops) => <EngineerReports {...matchprops} />}
        />

        <MainLayoutRoute
          exact
          path={"/engineer/labeling"}
          render={(matchprops) => <EngineerLableing {...matchprops} />}
        />
      </Switch>
    </BrowserRouter>
  );
}
