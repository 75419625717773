import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import moment from "moment";
import {
  getAllProjects,
  getAllEngineers,
  createProject,
  deleteProject,
  updateProject,
  getAllAdminDocumentTypes,
} from "../../../Api/restApi";
const { confirm } = Modal;
const { Search } = Input;
const { Option } = Select;

export default function Projects() {
  const { t } = useTranslation();
  // all states define here
  const [addDrawer, setAddrawer] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [allProjects, setAllProjects] = useState([]);
  const [allProjectsDetials, setAllProjectsDetials] = useState([]);
  const [allEngineers, setAllEngineers] = useState([]);
  const [data, setData] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const [createLoading, setIsCreateLoading] = useState(false);
  const [selectProjectId, setSelectProjectId] = useState("");
  const [selectDrawer, setSelectDrawer] = useState(true);
  const [documentType, setDocumentType] = useState([]);

  // Open new drawer
  const showAddDrawer = () => {
    form.resetFields();
    setAddrawer(true);
    setSelectDrawer(true);
  };

  // Close drawer
  const closeAddDrawer = () => {
    setAddrawer(false);
  };

  const columns = [
    {
      title: t("Admin.Projects.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Admin.Projects.TC02"),
      dataIndex: "activeSince",
      sorter: (a, b) => {
        return String(a?.activeSince).localeCompare(b?.activeSince);
      },
    },
    {
      title: t("Admin.Projects.TC03"),
      dataIndex: "assigned_engineer",
      sorter: (a, b) => a.assigned_engineer.length - b.assigned_engineer.length,
    },
    {
      title: t("Engineer.Dashboard.TC04"),
      dataIndex: "document_types",
      sorter: (a, b) => a.document_types.length - b.document_types.length,
      render: (data, record) => handleDocumentType(data),
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  const handleDocumentType = (data) => {
    let name = [];
    if (data !== null && data !== "") {
      data.map((item) => {
        documentType.map((value) => {
          if (value?.id === item) {
            name.push(value.name);
          }
          return name;
        });
        return name;
      });
    }
    return name.toString();
  };

  // Edit drawer open
  const showEditDrawer = (record) => {
    setSelectProjectId(record.key);
    setSelectDrawer(false);
    form.setFieldsValue(record);
    setAddrawer(true);
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allProjectsDetials.filter((item) =>
        item.name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setAllProjects(tempSearchData);
    } else {
      setAllProjects(allProjectsDetials);
    }
  };

  //delete engineer
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(data.map((item) => deleteProject(item)))
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Project.Delete"));
          setData([]);
          fetchAllProjectsData(allEngineers);
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Create a new project
  const addProject = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    let allData = {
      name: data.name,
      assigned_engineer_id: data.engineer,
    };
    await createProject(allData)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Project.Create"));
          fetchAllProjectsData(allEngineers);
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Update project
  const UpdateProject = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    let all = {
      id: selectProjectId,
      name: data.name,
      assigned_engineer_id: data.engineer,
    };
    await updateProject(all)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Project.Update"));
          fetchAllProjectsData(allEngineers);
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  const setEngineers = (data, engineers) => {
    let tempEngineers = [];
    data.map((item) => {
      engineers.map((ele) => {
        if (item === ele?.key) {
          tempEngineers.push(ele?.name);
        }
        return tempEngineers;
      });
      return tempEngineers;
    });
    return tempEngineers.toString();
  };

  //fetch all project's details
  const fetchAllProjectsData = async (engineers) => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAllProjects()
      .then((res) => {
        Object.entries(res).map(([key, value]) => {
          tempArray.push({
            key: key,
            name: value.name,
            engineer: value.assigned_engineer_id,
            assigned_engineer: setEngineers(
              value.assigned_engineer_id,
              engineers
            ),
            activeSince: moment(value.created_at).format("l"),
            document_types: value?.document_type_id,
          });
          return tempArray;
        });
        dispatch(setLoader(false));
        setAllProjectsDetials(tempArray);
        return setAllProjects(tempArray);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  //fetch all engineer's details
  const fetchAllEngineersData = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAllEngineers()
      .then((res) => {
        res.map((e) =>
          tempArray.push({
            key: e.user_sub,
            name: e.first_name + " " + e.last_name,
          })
        );
        fetchAllProjectsData(tempArray);
        setAllEngineers(tempArray);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  const fetchDocumentTypes = async () => {
    await getAllAdminDocumentTypes()
      .then((res) => setDocumentType(res))
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
      });
  };

  useEffect(() => {
    fetchAllEngineersData();
    fetchDocumentTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Admin.Projects.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={allProjects}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Admin.Projects.DrawerTitle")
              : t("Admin.Projects.DrawerUpdateTitle")}
          </span>
        }
        placement="right"
        onClose={closeAddDrawer}
        visible={addDrawer}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={selectDrawer ? addProject : UpdateProject}
          requiredMark={false}
        >
          <Form.Item
            name="name"
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Projects.DrawerName")}
              </strong>
            }
            rules={[
              {
                required: true,
                message: t("Error.Admin.Project.P01"),
              },
            ]}
          >
            <Input placeholder={t("Placeholder.Admin.Project.DrawerName")} />
          </Form.Item>

          <Form.Item
            name="engineer"
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Projects.DrawerEngineer")}
              </strong>
            }
            rules={[
              {
                required: true,
                message: t("Error.Admin.Project.P02"),
              },
            ]}
          >
            <Select
              placeholder={t("Placeholder.Admin.Project.DrawerEngineer")}
              style={{ width: "100%" }}
              mode="multiple"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0 ||
                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
              }
            >
              {allEngineers.map((item, index) => {
                return (
                  <Option key={index} value={item.key}>
                    {item?.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              loading={createLoading}
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
