import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Modal,
  message,
  Spin,
} from "antd";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import moment from "moment";
import {
  getAllComapnies,
  createComapny,
  deleteCompany,
  updateCompany,
  getCompanyProject,
  getAllEngineerProject,
} from "../../../Api/restApi";
import { Link } from "react-router-dom";
const { confirm } = Modal;
const { Search } = Input;

export default function Companies() {
  const { t } = useTranslation();
  const [addDrawer, setAddrawer] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [allCompanys, setAllCompanys] = useState([]);
  const [allCompanysDetails, serAllComapnysDetails] = useState([]);
  const [allProjectDetails, setAllProjectDetails] = useState([]);
  const [data, setData] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const [createLoading, setIsCreateLoading] = useState(false);
  const [selectCompanyId, setSelectCompanyId] = useState("");
  const [selectDrawer, setSelectDrawer] = useState(true);
  const showAddDrawer = () => {
    setSelectDrawer(true);
    form.resetFields();
    setAddrawer(true);
  };

  const closeAddDrawer = () => {
    setAddrawer(false);
  };
  const showEditDrawer = (record) => {
    setSelectCompanyId(record.key);
    setSelectDrawer(false);
    form.setFieldsValue(record);
    setAddrawer(true);
  };

  const columns = [
    {
      title: t("Engineer.Companies.TC01"),
      dataIndex: "company_name",
      render: (data, record) => (
        <Link
          to={{
            pathname: "/company-representative",
            state: { data: record },
          }}
        >
          {data}
        </Link>
      ),
      sorter: (a, b) => a.company_name.length - b.company_name.length,
    },
    {
      title: t("Engineer.Companies.TC02"),
      dataIndex: "created_at",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
    },
    {
      title: t("Engineer.Companies.TC03"),
      dataIndex: "companiesAssigned",
      sorter: (a, b) => a.companiesAssigned.length - b.companiesAssigned.length,
      render: (data, record) => handleCompanyAssignment(data),
    },

    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  const handleCompanyAssignment = (data) => {
    let name = [];
    if (data !== null && data !== "") {
      data.map((item) => {
        Object.entries(allProjectDetails).map(([key, value]) => {
          if (key === item) {
            name.push(value.name);
          }
          return name;
        });
        return name;
      });
    }
    return name.toString();
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allCompanysDetails.filter((label) =>
        label.company_name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setAllCompanys(tempSearchData);
    } else {
      setAllCompanys(allCompanysDetails);
    }
  };

  //delete company
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(data.map((item) => deleteCompany(item)))
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Companies.Delete"));
          setData([]);
          fetchAllCompaniesData();
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const addCompany = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue([
      "company_name",
      "address",
      "nipc",
      "postal_code",
    ]);
    let org_id = JSON.parse(window.localStorage.getItem("user-attributes")).sub;
    let inputData = { org_id, ...data };
    await createComapny(inputData)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Companies.Create"));
          fetchAllCompaniesData();
        }
      })
      .catch((err) => {
        if (err.response.data.Message === "Duplicate NIPC") {
          message.error(t("ErrorDupilicateNIPC"));
          setIsCreateLoading(false);
        } else {
          setIsCreateLoading(false);
          setAddrawer(false);
          message.error(t("SomthingWentWrong"));
        }
      });
  };

  const UpdateCompany = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue([
      "company_name",
      "address",
      "nipc",
      "postal_code",
    ]);
    let all = {
      id: selectCompanyId,
      data: { ...data },
    };
    await updateCompany(all)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Companies.Update"));
          fetchAllCompaniesData();
        }
      })
      .catch((err) => {
        if (err.response.data.Message === "Duplicate NIPC") {
          message.error(t("ErrorDupilicateNIPC"));
          setIsCreateLoading(false);
        } else {
          setIsCreateLoading(false);
          setAddrawer(false);
          message.error(t("SomthingWentWrong"));
        }
      });
  };

  //fetch all Company's details
  const fetchAllCompaniesData = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAllComapnies()
      .then(async (res) => {
        res.map((e) => {
          tempArray.push({
            key: e.company_id,
            company_name: e.company_name,
            address: e.address,
            nipc: e.nipc,
            postal_code: e.postal_code,
            created_at: moment(e.created_at).format("l"),
            companiesAssigned: "",
          });
          return tempArray;
        });
        await getCompanyProject().then((res) =>
          tempArray.map((item) => {
            let temp = [];
            res.map((value) => {
              Object.values(value?.project_id)[0].map((data) => {
                if (item?.key === data) {
                  temp.push(Object.keys(value?.project_id)[0]);
                }
                return temp;
              });
              return item;
            });
            item["companiesAssigned"] = temp;
            return item;
          })
        );
        setAllCompanys(tempArray);
        serAllComapnysDetails(tempArray);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  const fetchAllEngineerProject = async () => {
    let engineerId = JSON.parse(
      window.localStorage.getItem("user-attributes")
    ).sub;
    await getAllEngineerProject(engineerId)
      .then((res) => setAllProjectDetails(res))
      .catch((err) => {
        console.error(t("SomthingWentWrong"));
      });
  };

  const anyValidation = (rule, value, callback) => {
    if (value.length === 5 || value.length === 8) {
      callback();
    } else {
      callback(t("ErrorPostalCode"));
    }
  };

  useEffect(() => {
    fetchAllEngineerProject();
    fetchAllCompaniesData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Companies.Title")}
              </h2>
            </Col>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={allCompanys}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Engineer.Companies.DrawerAddTitle")
              : t("Engineer.Companies.DrawerEditTitle")}
          </span>
        }
        placement="right"
        onClose={closeAddDrawer}
        visible={addDrawer}
      >
        <Form
          form={form}
          onFinish={selectDrawer ? addCompany : UpdateCompany}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Engineer.Companies.DrawerName")}
              </strong>
            }
            name="company_name"
            rules={[
              {
                required: true,
                message: t("Error.Engineer.Companies.C01"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Engineer.Companies.DrawerName")}
            />
          </Form.Item>
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Engineer.Companies.DrawerAddress")}
              </strong>
            }
            name="address"
            rules={[
              {
                required: true,
                message: t("Error.Engineer.Companies.C02"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Engineer.Companies.DrawerAddress")}
            />
          </Form.Item>
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Engineer.Companies.DrawerNIPC")}
              </strong>
            }
            name="nipc"
            rules={[
              {
                required: true,
                message: t("Error.Engineer.Companies.C03"),
              },
              {
                pattern: /^[a-zA-Z0-9]*$/,
                message: t("ErrorNIPC"),
              },
              {
                max: 9,
                message: t("ErrorNIPClength"),
              },
              {
                min: 9,
                message: t("ErrorNIPClength"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Engineer.Companies.DrawerNIPC")}
            />
          </Form.Item>
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Engineer.Companies.DrawerPostalcode")}
              </strong>
            }
            name="postal_code"
            rules={[
              {
                required: true,
                message: t("Error.Engineer.Companies.C04"),
              },
              { validator: anyValidation },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Engineer.Companies.DrawerPostalcode")}
            />
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
              loading={createLoading}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
