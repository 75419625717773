import {
  Button,
  Col,
  Collapse,
  Row,
  Table,
  message,
  Input,
  Modal,
  Select,
  Form,
  Spin,
  Drawer,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  getAdminEquipment,
  getAdminEquipmentType,
  createEquipment,
  updateEquipment,
  deleteEquipment,
  getEngineerEquipment,
} from "../../../Api/restApi";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Documents from "../../Engineer/Documents/Index";
import Document from "../../Admin/Documents/Index";
import { setLoader } from "../../../store/Actions";
import { useDispatch, useSelector } from "react-redux";
const { Panel } = Collapse;

const { Search } = Input;
const { confirm } = Modal;
const { Option } = Select;

const genExtra = () => (
  <Row justify="end">
    <Button
      style={{
        color: "#00A8CC",
        borderColor: "#00A8CC",
        borderRadius: 5,
        marginRight: 10,
      }}
    >
      <PlusOutlined />
    </Button>
    <Button
      style={{
        color: "#00A8CC",
        borderColor: "#00A8CC",
        borderRadius: 5,
        marginRight: 10,
      }}
    >
      <EditFilled />
    </Button>
    <Button
      style={{ color: "#00A8CC", borderColor: "#00A8CC", borderRadius: 5 }}
    >
      <DeleteFilled />
    </Button>
  </Row>
);

export default function ComapnyRepresentative(props) {
  const companiesData = props?.location?.state?.data;
  const { t } = useTranslation();
  const [documents, setDocument] = useState(0);

  const [addDrawer, setAddrawer] = useState(false);
  const [allEquipment, setAllEquipment] = useState([]);
  const [equipmentType, setEquipmentType] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const isLoading = useSelector((state) => state.isLoading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [selectDrawer, setSelectDrawer] = useState(true);
  const [createLoading, setIsCreateLoading] = useState(false);
  const [selectCompanyId, setSelectCompanyId] = useState("");

  const columns = [
    {
      title: t("Admin.Equipment.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Admin.Equipment.TC02"),
      dataIndex: "identifier",
      sorter: (a, b) => a.identifier.length - b.identifier.length,
    },
    {
      title: t("Admin.Equipment.TC03"),
      dataIndex: "createdAt",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
      render: (data, record) => {
        return <span>{moment(data).format("l")}</span>;
      },
    },
    {
      title: "",
      dataIndex: "edit",
      className:
        localStorage.getItem("Role") === "engineers" ? "hiddenCol" : null,
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  // Open new drawer
  const showAddDrawer = () => {
    form.resetFields();
    setAddrawer(true);
    setSelectDrawer(true);
  };

  // Close drawer
  const closeAddDrawer = () => {
    setAddrawer(false);
  };

  const showEditDrawer = (record) => {
    setSelectCompanyId(record.key);
    setSelectDrawer(false);
    form.setFieldsValue(record);
    setAddrawer(true);
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  //delete Equipmant
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(data.map((item) => deleteEquipment(item)))
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Equipment.Delete"));
          setData([]);
          fetchEquipment();
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  // Create a new equipment
  const addEquipment = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();

    let allData = {
      identifier: data?.identifier,
      equipment_type_id: data?.equipment_type_id,
    };
    await createEquipment(allData)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Equipment.Create"));
          fetchEquipment();
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Update equipment
  const UpdateEquipment = async () => {
    setIsCreateLoading(true);
    let data = form.getFieldsValue();
    let all = {
      id: selectCompanyId,
      identifier: data?.identifier,
      equipment_type_id: data?.equipment_type_id,
    };
    await updateEquipment(all)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Equipment.Update"));
          fetchEquipment();
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allEquipment.filter((label) =>
        label.name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setEquipment(tempSearchData);
    } else {
      setEquipment(allEquipment);
    }
  };

  const fetchEquipment = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAdminEquipment()
      .then((res) => {
        res.map((item) => {
          tempArray.push({
            key: item?.id,
            name: item?.name,
            identifier: item?.identifier,
            equipment_type_id: item?.equipment_type_id,
          });
          return tempArray;
        });
        setEquipment(tempArray);
        setAllEquipment(tempArray);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        message.error(t("SomthingWentWrong"));
      });
  };

  const fetchEquipmentType = async () => {
    await getAdminEquipmentType()
      .then((res) => {
        setEquipmentType(res);
      })
      .catch((err) => message.error(t("SomthingWentWrong")));
  };

  const fetchEngineerEquipment = async () => {
    dispatch(setLoader(true));

    await getEngineerEquipment()
      .then((res) => {
        setEquipment(res);
        setAllEquipment(res);
        dispatch(setLoader(false));
      })
      .catch((err) => {
        dispatch(setLoader(false));
        message.error(t("SomthingWentWrong"));
      });
  };

  useEffect(() => {
    if (localStorage.getItem("Role") === "engineers") {
      fetchEquipment();
      fetchEquipmentType();
    } else {
      fetchEngineerEquipment();
    }
    // eslint-disable-next-line
  }, []);

  const genEquipmentExtra = () => (
    <Row justify="end">
      <div className="searchbar">
        <Search
          placeholder={t("Search")}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
      </div>
      <Button
        onClick={showAddDrawer}
        style={{
          color: "#00A8CC",
          borderColor: "#00A8CC",
          borderRadius: 5,
          marginRight: 10,
        }}
      >
        <PlusOutlined />
      </Button>
      <Button
        onClick={showConfirm}
        style={{ color: "#00A8CC", borderColor: "#00A8CC", borderRadius: 5 }}
        disabled={data.length > 0 ? false : true}
      >
        <DeleteFilled />
      </Button>
    </Row>
  );

  const genEngineerEquipmentExtra = () => (
    <Row justify="end">
      <div className="searchbar">
        <Search
          placeholder={t("Search")}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
        />
      </div>
    </Row>
  );

  return (
    <>
      <div style={{ borderRadius: 7, background: "white", marginBottom: 6 }}>
        <Row style={{ padding: "10px 25px" }}>
          <Col span={12} md={12} lg={12} sm={24} xs={24}>
            <h2 style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}>
              {companiesData?.company_name}
            </h2>
          </Col>
        </Row>
        <Row style={{ padding: "10px 25px" }}>
          <Col span={12} md={12} lg={12} sm={24} xs={24}>
            <h2 style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}>
              {t("Engineer.Companies.DrawerNIPC")} :
              <Typography.Text
                copyable={{
                  text: companiesData?.nipc,
                }}
              >
                {companiesData?.nipc}
              </Typography.Text>
            </h2>
          </Col>
          <Col span={12} md={12} lg={12} sm={24} xs={24}>
            <h2 style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}>
              Morada :
              <Typography.Text
                copyable={{
                  text: companiesData?.address + " " + companiesData?.postal_code,
                }}
              >
                {companiesData?.address} {companiesData?.postal_code}
              </Typography.Text>
            </h2>
          </Col>
        </Row>
      </div>
      <div style={{ borderRadius: 7, background: "white", marginBottom: 10 }}>
        <div style={{ margin: "0 20px" }}>
          <Row gutter={20} className="projects">
            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <div className="card active">
                <h2>{t("CompanyRepresentative.Dashboard.Employees")}</h2>
                <Row justify="space-between" align="middle">
                  <h1>{t("CompanyRepresentative.Dashboard.Number")}</h1>
                </Row>
              </div>
            </Col>

            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <div className="card">
                <h2>{t("CompanyRepresentative.Dashboard.Equipment")}</h2>
                <Row justify="space-between" align="middle">
                  <h1>{allEquipment.length}</h1>
                </Row>
              </div>
            </Col>

            <Col span={8} lg={8} md={8} sm={24} xs={24}>
              <div className="card">
                <h2>{t("CompanyRepresentative.Dashboard.Documents")}</h2>
                <Row justify="space-between" align="middle">
                  <h1>{documents}</h1>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Collapse
        accordion
        expandIconPosition="right"
        bordered={false}
        defaultActiveKey="1"
        className="lists collaps"
      >
        {localStorage.getItem("Role") === "engineers" ? (
          <Documents
            tableCss={true}
            company_id={props?.location?.state?.data?.key}
            setDocument={setDocument}
          />
        ) : (
          <Document
            tableCss={true}
            company_id={props?.location?.state?.data?.key}
            setDocument={setDocument}
          />
        )}

        <Panel
          header={
            <h3 style={{ marginBottom: 0, fontWeight: 600 }}>
              {t("CompanyRepresentative.Dashboard.EmployeeList")}
            </h3>
          }
          key="2"
          style={{ marginTop: "20px" }}
          extra={genExtra()}
        >
          <Table
            rowSelection={{
              type: "checkbox",
            }}
            locale={{ emptyText: t("NoData") }}
            columns={columns}
            dataSource={data}
            bordered
            className="document-table"
            pagination={false}
          />
        </Panel>

        <Panel
          header={
            <h3 style={{ marginBottom: 0, fontWeight: 600 }}>
              {t("CompanyRepresentative.Dashboard.EquipmentList")}
            </h3>
          }
          key="3"
          style={{ borderRadius: 7 }}
          extra={
            localStorage.getItem("Role") === "engineers"
              ? genEngineerEquipmentExtra()
              : genEquipmentExtra()
          }
        >
          <Spin spinning={isLoading}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={equipment}
              bordered
              className="document-table"
            />
          </Spin>
        </Panel>
      </Collapse>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Admin.Equipment.AddDrawerTitle")
              : t("Admin.Equipment.EditDrawerTitle")}
          </span>
        }
        placement="right"
        onClose={closeAddDrawer}
        visible={addDrawer}
      >
        <Form
          form={form}
          onFinish={selectDrawer ? addEquipment : UpdateEquipment}
          requiredMark={false}
          layout="vertical"
        >
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Equipment.EquipmentName")}
              </strong>
            }
            name="identifier"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Equipment.E01"),
              },
            ]}
          >
            <Input placeholder={t("Placeholder.Admin.Equipment.DrawerName")} />
          </Form.Item>
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Equipment.EquipmentType")}
              </strong>
            }
            name="equipment_type_id"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Equipment.E02"),
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder={t("Placeholder.Admin.Equipment.DrawerEquipmentType")}
            >
              {equipmentType.map((item) => {
                return <Option value={item?.id}>{item?.name}</Option>;
              })}
            </Select>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
              loading={createLoading}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
