const initial_state = {
  loginData: {},
  app_name: "Katia",
  isLoading: false,
  token: null,
  languageJSON: {},
  firstTime: true,
  menuItem: [],
};
const reducer = (state = initial_state, action) => {
  switch (action.type) {
    case "SET_LOGIN_DATA":
      return {
        ...state,
        loginData: action.payload,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };

    case "SET_LOADER":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_LANGUAGE_JSON":
      return {
        ...state,
        languageJSON: action.payload,
      };
    case "SET_FIRST_TIME":
      return {
        ...state,
        firstTime: action.payload,
      };
    case "SET_MENU_ITEM":
      return {
        ...state,
        menuItem: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
