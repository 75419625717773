import { t } from "i18next";
import React from "react";

function Footer() {
  return (
    <div
      style={{
        color: "#959595",
        fontSize: "12px",
        textAlign: "center",
        fontWeight: "300",
        position: "absolute",
        bottom: "20px",
        left: 0,
        right: 0,
        margin: "0 auto",
      }}
    >
      {t("login.copyright")}
    </div>
  );
}
export default Footer;
