/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:19f7320f-ba48-415c-8119-ea7ca31cd705",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_qWQnfZ4Rn",
    "aws_user_pools_web_client_id": "719m54bs9555p9b81v4d7b9jns",
    "oauth": {
        "domain": "katia01b86425-01b86425-prod.auth.us-east-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:3000/",
        "redirectSignOut": "https://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "restapi",
            "endpoint": "https://523vjjmbr3.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_content_delivery_bucket": "katia-20220601190318-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d3uznezax714mk.cloudfront.net",
    "aws_user_files_s3_bucket": "katia8eea93c6a6eb437a8b351bc08398736e115108-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
