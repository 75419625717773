import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Select,
  Modal,
  message,
  Spin,
} from "antd";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  getAllEnginnerDocuments,
  getAllComapnies,
  createEngineerDocuments,
  updateEngineerDocuments,
  deleteEngineerDocument,
  getAllDocumentTypes,
  uploadFileToS3,
  removeFileToS3,
} from "../../../Api/restApi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import { Storage } from "aws-amplify";

const { confirm } = Modal;
const { Option } = Select;
const { Search } = Input;

export default function Documents(props) {
  const { t } = useTranslation();
  const [editDrawer, setEditDrawer] = useState(false);
  const [companiesData, setCompaniesData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const [labelForm] = Form.useForm();
  const [labelAddLoader, setLabelAddLoader] = useState(false);
  const { isLoading } = useSelector((state) => state);
  const [selectLabelId, setSelectLabelId] = useState("");
  const [selectDrawer, setSelectDrawer] = useState(true);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [allLabelData, setAllLabelData] = useState([]);
  const [fileList, setFileList] = useState(null);
  const [labelIdData, setLabelIdData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: t("Engineer.Documents.TC01"),
      dataIndex: "file_name",
      sorter: (a, b) => a.file_name.length - b.file_name.length,
      render: (data, record) => (
        <Button
          style={{ border: "none", color: "#1890FF" }}
          onClick={() => showModal(record)}
        >
          {data}
        </Button>
      ),
    },
    {
      title: t("Engineer.Documents.TC02"),
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name.length - b.company_name.length,
      hidden: props.tableCss ? true : false,
    },
    {
      title: t("Engineer.Documents.TC06"),
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      render: (data, record) => record.first_name + " " + record.last_name,
    },
    {
      title: t("Engineer.Documents.TC03"),
      dataIndex: "document_name",
      sorter: (a, b) => a.document_name.length - b.document_name.length,
    },
    {
      title: t("Engineer.Documents.TC04"),
      dataIndex: "created_at",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
    },
    {
      title: t("Engineer.Documents.TC05"),
      dataIndex: "has_pii",
      sorter: (a, b) => a.has_pii.length - b.has_pii.length,
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ].filter((item) => !item.hidden);

  const showAddDrawer = () => {
    if (props?.company_id) {
      setSelectDrawer(true);
      labelForm.resetFields();
      let data = { company_id: props?.company_id };
      labelForm.setFieldsValue(data);
      setEditDrawer(true);
    } else {
      setSelectDrawer(true);
      labelForm.resetFields();
      setEditDrawer(true);
    }
  };

  const showEditDrawer = (record) => {
    labelForm.resetFields();
    setLabelIdData(record);
    setSelectLabelId(record.key);
    setSelectDrawer(false);
    setFileList({});
    labelForm.setFieldsValue(record);
    setEditDrawer(true);
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  const closeEditDrawer = () => {
    setEditDrawer(false);
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Fetch all label's details
  const fetchAllLabels = async () => {
    dispatch(setLoader(true));
    await getAllEnginnerDocuments()
      .then((res) => {
        let tempArray = [];
        if (res !== null) {
          res.map((e) => {
            tempArray.push({
              key: e.id,
              company_id: e.companyid,
              company_name: e.company,
              file_name: e.filename.split("/")[2],
              file_path: e.filename,
              first_name: e.first_name || "",
              last_name: e.last_name || "",
              created_at: moment(e.created_at).format("l"),
              document_name: e.document_type_name,
              document_type: e.document_type_id,
              has_pii: e.has_pii ? "Yes" : "No",
            });

            return tempArray;
          });
        }
        Promise.all(
          tempArray.map((item) => {
            return gets3link(item.file_path);
          })
        )
          .then((data) =>
            data.map((item, index) => {
              return (tempArray[index]["file_link"] = item);
            })
          )
          .catch((err) => {
            console.log(err);
          });
        dispatch(setLoader(false));
        if (props?.company_id) {
          let filterData = tempArray.filter(
            (item) => item.company_id === props.company_id
          );
          props.setDocument(filterData.length);
          setAllLabelData(filterData);
          setLabelData(filterData);
        } else {
          setAllLabelData(tempArray);
          setLabelData(tempArray);
        }
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  const gets3link = (key) => {
    return Storage.get(key);
  };

  // Fetch all documents type
  const fetchAllDocumentTypes = async () => {
    dispatch(setLoader(true));
    await getAllDocumentTypes()
      .then((response) => {
        setDocumentType(response);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  // Create a new label
  const addLabel = async () => {
    setLabelAddLoader(true);
    let form = labelForm.getFieldsValue();
    let org_id = JSON.parse(window.localStorage.getItem("user-attributes")).sub;
    let company_id = form.company_id;

    await uploadFileToS3(fileList, org_id, company_id)
      .then(async (response) => {
        let tempInput = {
          file_name: response.key,
          company_id: form.company_id,
          document_type: form.document_type,
          inserted_by_engineer_id: JSON.parse(
            window.localStorage.getItem("user-attributes")
          ).sub,
          manual_label: 1,
        };
        await createEngineerDocuments(tempInput)
          .then((res) => {
            setLabelAddLoader(false);
            if (res.message === "Success") {
              message.success(t("SuccessfullMsg.Engineer.Document.Create"));
              fetchAllLabels(documentType);
            }
          })
          .catch((err) => {
            setLabelAddLoader(false);
            setEditDrawer(false);
            message.error(t("SomthingWentWrong"));
          });
      })
      .catch((err) => {
        setLabelAddLoader(false);
        console.log(err);
        message.error("Error while uploading file");
      });
  };

  // Update Label
  const UpdateLabel = async () => {
    setLabelAddLoader(true);
    let form = labelForm.getFieldsValue();
    if (fileList?.name) {
      await Storage.remove(labelIdData?.file_path).then(async (res) => {
        let org_id = JSON.parse(
          window.localStorage.getItem("user-attributes")
        ).sub;
        let company_id = form.company_id;
        await uploadFileToS3(fileList, org_id, company_id)
          .then(async (response) => {
            let tempInput = {
              id: selectLabelId,
              data: {
                file_name: response.key,
                company_id: form.company_id,
                document_type: form.document_type,
                inserted_by_engineer_id: JSON.parse(
                  window.localStorage.getItem("user-attributes")
                ).sub,
              },
            };
            await updateEngineerDocuments(tempInput)
              .then((res) => {
                setLabelAddLoader(false);
                if (res.message === "Success") {
                  message.success(t("SuccessfullMsg.Engineer.Document.Update"));
                  fetchAllLabels(documentType);
                }
              })
              .catch((err) => {
                setLabelAddLoader(false);
                setEditDrawer(false);
                message.error(t("SomthingWentWrong"));
              });
          })
          .catch((err) => {
            setLabelAddLoader(false);
            console.log(err);
            message.error("Error while uploading file");
          });
      });
    } else {
      let tempInput = {
        id: selectLabelId,
        data: {
          file_name: labelIdData?.file_path,
          company_id: form.company_id,
          document_type: form.document_type,
        },
      };
      await updateEngineerDocuments(tempInput)
        .then((res) => {
          setLabelAddLoader(false);
          setEditDrawer(false);
          if (res.message === "Success") {
            message.success(t("SuccessfullMsg.Engineer.Document.Update"));
            fetchAllLabels(documentType);
          }
        })
        .catch((err) => {
          setLabelAddLoader(false);
          setEditDrawer(false);
          message.error(t("SomthingWentWrong"));
        });
    }
  };

  // Delete label
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(
      data.map((item) => {
        RemoveFileToS3(item);
        return deleteEngineerDocument(item);
      })
    )
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Labelling.Delete"));
          setData([]);
          fetchAllLabels(documentType);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
      });
  };

  const RemoveFileToS3 = async (item) => {
    let removeData = labelData.filter((value) => value.key === item);
    let file = removeData[0]?.file_path;

    await removeFileToS3(file)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allLabelData.filter((label) =>
        label.file_name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setLabelData(tempSearchData);
    } else {
      setLabelData(allLabelData);
    }
  };

  const showModal = (record) => {
    setLabelIdData(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchAllCompaniesData = async () => {
    getAllComapnies()
      .then((res) => {
        setCompaniesData(res);
      })
      .catch((err) => message.error(t("SomthingWentWrong")));
  };

  useEffect(() => {
    fetchAllLabels();
    fetchAllDocumentTypes();
    fetchAllCompaniesData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Documents.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Spin spinning={isLoading}>
          <div style={{ padding: props.tableCss ? "0px" : "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={labelData}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Engineer.Documents.AddDrawerTitle")
              : t("Engineer.Documents.EditDrawerTitle")}
          </span>
        }
        placement="right"
        onClose={closeEditDrawer}
        visible={editDrawer}
      >
        {selectDrawer ? (
          <Form
            form={labelForm}
            onFinish={addLabel}
            requiredMark={false}
            layout="vertical"
          >
            <Form.Item
              name="file_name"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Documents.EditDrawerFile")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Document.D01"),
                },
              ]}
            >
              <Input
                id="myInputFileID"
                type="file"
                onChange={(e) => setFileList(e.target.files[0])}
                placeholder={t("Placeholder.Engineer.Labelling.DrawerFile")}
              />
            </Form.Item>

            <Form.Item
              name="company_id"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Documents.EditDrawerCompany")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Document.D03"),
                },
              ]}
            >
              <Select
                placeholder={t("Placeholder.Engineer.Documents.DrawerCompany")}
                style={{ width: "100%" }}
                disabled={props?.company_id ? true : false}
              >
                {companiesData.length > 0 && companiesData !== null
                  ? companiesData.map((item, index) => {
                      return (
                        <Option key={index} value={item.company_id}>
                          {item?.company_name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Form.Item
              name="document_type"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Documents.EditDrawerDocumentType")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Document.D02"),
                },
              ]}
            >
              <Select
                placeholder={t(
                  "Placeholder.Engineer.Documents.DrawerDocumentType"
                )}
                style={{ width: "100%" }}
              >
                {documentType.length > 0 && documentType !== null
                  ? documentType.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item?.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "left",
                display: "flex",
              }}
            >
              <Button
                className="secondary-btn"
                onClick={() => setEditDrawer(false)}
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  marginRight: 8,
                  height: "40px",
                  background: "transparent",
                  borderColor: "#00A8CC",
                  color: "#00A8CC",
                }}
              >
                {t("Cancle")}
              </Button>
              <Button
                htmlType="submit"
                loading={labelAddLoader}
                type="primary"
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  height: "40px",
                  background: "#00A8CC",
                  borderColor: "#00A8CC",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                {t("Create")}
              </Button>
            </div>
          </Form>
        ) : (
          <Form
            form={labelForm}
            onFinish={UpdateLabel}
            requiredMark={false}
            layout="vertical"
          >
            <Form.Item
              name="file_name"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Documents.EditDrawerFile")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Document.D01"),
                },
              ]}
            >
              <Input
                id="myInputFileID"
                type="file"
                onChange={(e) => setFileList(e.target.files[0])}
                placeholder={t("Placeholder.Engineer.Labelling.DrawerFile")}
              />
              {!selectDrawer
                ? fileList?.name
                  ? null
                  : labelIdData?.file_name
                : null}
            </Form.Item>

            <Form.Item
              name="company_id"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Documents.EditDrawerCompany")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Document.D03"),
                },
              ]}
            >
              <Select
                placeholder={t("Placeholder.Engineer.Documents.DrawerCompany")}
                style={{ width: "100%" }}
                disabled={props?.company_id ? true : false}
              >
                {companiesData.length > 0 && companiesData !== null
                  ? companiesData.map((item, index) => {
                      return (
                        <Option key={index} value={item.company_id}>
                          {item?.company_name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Form.Item
              name="document_type"
              label={
                <strong style={{ color: "#7E7E7E" }}>
                  {t("Engineer.Documents.EditDrawerDocumentType")}
                </strong>
              }
              rules={[
                {
                  required: true,
                  message: t("Error.Engineer.Document.D02"),
                },
              ]}
            >
              <Select
                placeholder={t(
                  "Placeholder.Engineer.Documents.DrawerDocumentType"
                )}
                style={{ width: "100%" }}
              >
                {documentType.length > 0 && documentType !== null
                  ? documentType.map((item, index) => {
                      return (
                        <Option key={index} value={item.id}>
                          {item?.name}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>

            <div
              style={{
                position: "absolute",
                right: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#EFF4F9",
                textAlign: "left",
                display: "flex",
              }}
            >
              <Button
                className="secondary-btn"
                onClick={() => setEditDrawer(false)}
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  marginRight: 8,
                  height: "40px",
                  background: "transparent",
                  borderColor: "#00A8CC",
                  color: "#00A8CC",
                }}
              >
                {t("Cancle")}
              </Button>
              <Button
                htmlType="submit"
                loading={labelAddLoader}
                type="primary"
                style={{
                  fontSize: 16,
                  borderRadius: 8,
                  flexGrow: 1,
                  flexBasis: 0,
                  height: "40px",
                  background: "#00A8CC",
                  borderColor: "#00A8CC",
                  fontWeight: 600,
                  color: "white",
                }}
              >
                {t("Update")}
              </Button>
            </div>
          </Form>
        )}
      </Drawer>

      <Modal
        title={labelIdData?.file_name}
        footer=""
        visible={isModalVisible}
        style={{ width: "35%" }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe
          src={labelIdData?.file_link}
          title="myFrame"
          style={{ height: "500px" }}
          scrolling="no"
          width="100%"
          frameborder="0"
        ></iframe>
      </Modal>
    </>
  );
}
