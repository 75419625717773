import React, { Component } from "react";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Space,
  Typography,
} from "antd";
import "./mainLayout.css";
import logo from "../assets/images/whiteLogo.svg";
import favicon from "../assets/images/favicon.svg";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import SubMenu from "antd/lib/menu/SubMenu";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setMenuItem } from "../store/Actions";
const { Header, Sider, Content } = Layout;
class MainLayout extends Component {
  constructor(props) {
    super(props);
    const location = this.props?.location?.pathname;
    const tieringAccess = {
      dashboard: {
        enable: true,
      },
      billing: {
        enable: true,
      },
      users: {
        enable: true,
      },
    };
    this.state = {
      selectedKey: this.props.menuItem.filter(
        (menus) => menus.path === location
      )[0]
        ? this.props.menuItem.filter((menus) => menus.path === location)[0].key
        : "",
      tieringAccess: tieringAccess,
      settingsMenuOpen: false,
      userName: JSON.parse(localStorage.getItem("user-attributes")),
    };
  }

  render() {
    const { menuItem } = this.props;
    const { collapsed, selectedKey } = this.state;
    const toggle = () => {
      this.setState({ collapsed: !collapsed });
    };

    return (
      <div
        id="menu-container"
        className={
          collapsed ? "slidermenu collapsedOn" : "slidermenu collapsedOff"
        }
      >
        <Layout style={{ maxWidth: "100vw" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={() => this.setState({ collapsed: !collapsed })}
            width={260}
            style={{ height: "100vh", position: "fixed", zIndex: 9 }}
          >
            <div className="explorics-sider-menu">
              <div style={{ marginTop: "18px" }}>
                {collapsed ? (
                  <img
                    src={favicon}
                    alt=""
                    style={{ height: 30, display: "block", margin: "0 auto" }}
                  />
                ) : (
                  <img
                    src={logo}
                    alt=""
                    style={{ height: 40, display: "block", margin: "0 auto" }}
                  />
                )}

                <div data-tour="tour__modules">
                  <Menu
                    theme="dark"
                    mode="inline"
                    style={{ marginTop: "15px", background: "none" }}
                  >
                    {" "}
                    {menuItem.map((item, index) => {
                      const {
                        key,
                        title,
                        icon,
                        path,
                        isSubMenu,
                        name,
                        url,
                        icon_url,
                      } = item;
                      return isSubMenu ? (
                        <SubMenu
                          key={key}
                          className="subMenuItem"
                          icon={icon}
                          title={title}
                          selectedKeys={selectedKey === key}
                        >
                          {item.subMenu.map((subItem, index) => (
                            <Menu.Item key={subItem.key}>
                              <Link to={`${path}${subItem.path}`}>
                                {" "}
                                {subItem.title}
                              </Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      ) : (
                        <Menu.Item
                          key={name}
                          icon={<img src={icon_url} alt="icon" />}
                          id={name}
                          style={{ background: "none" }}
                          className={
                            window.location.pathname === url ? "selected" : ""
                          }
                        >
                          <Link to={url}>{name}</Link>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </div>
              </div>
              {!collapsed && (
                <div>
                  <h2
                    style={{
                      color: "#799FB9",
                      fontSize: "18px",
                      textAlign: "center",
                      fontWeight: "500",
                      userSelect: "none",
                    }}
                  >
                    Versão 1.0.1
                  </h2>
                  <p
                    style={{
                      fontSize: "16px",
                      color: "#799FB9",
                      textAlign: "center",
                      bottom: "0px",
                    }}
                  >
                    © 2022 Katia
                    <br />
                    copyright all rights reserved.
                  </p>
                </div>
              )}
            </div>
          </Sider>

          <Layout id="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              {React.createElement(
                collapsed ? DoubleRightOutlined : DoubleLeftOutlined,
                {
                  className: "trigger",
                  onClick: toggle,
                }
              )}
              <div style={{ float: "right", paddingRight: "24px" }}>
                <Space>
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    Bem-vindo {this.state.userName?.given_name}{" "}
                    {this.state.userName?.family_name}
                  </Typography.Title>
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item>
                          <Button
                            type="text"
                            onClick={() => {
                              localStorage.clear();
                              this.props.history.push("/login");
                            }}
                          >
                            Logout
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Avatar size={40}>
                      {this.state.userName?.given_name?.[0]}
                    </Avatar>
                  </Dropdown>
                </Space>
              </div>
            </Header>

            <Content
              className="mainLayout"
              style={{
                margin: "24px 21px",
                // minWidth: 400,
                background: "#EFF4F9",
              }}
            >
              {this.props.children}
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { menuItem } = state;
  return {
    prop: state.prop,
    menuItem,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMenuItem: (metaData) => dispatch(setMenuItem(metaData)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainLayout)
);
