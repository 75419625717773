import React, { useState, useEffect } from "react";
import {
  Button,
  Col,
  Drawer,
  Row,
  Table,
  Form,
  Input,
  Modal,
  message,
  Spin,
} from "antd";
import { useTranslation } from "react-i18next";
import {
  PlusOutlined,
  EditFilled,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  getAllEngineers,
  createEngineer,
  deleteEngineer,
  updateEngineer,
  getAllProjects
} from "../../../Api/restApi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import { Auth } from "aws-amplify";
const { confirm } = Modal;
const { Search } = Input;

export default function Dashboard() {
  const { t } = useTranslation();
  // all states define here
  const [addDrawer, setAddrawer] = useState(false);
  const [allEngineers, setAllEngineers] = useState([]);
  const [AllEngineersDetials, setAllEngineersDetials] = useState([]);
  const [data, setData] = useState([]);
  const [createLoading, setIsCreateLoading] = useState(false);
  const [selectDrawer, setSelectDrawer] = useState(true);
  const [selectEngineerId, setSelectEngineerId] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.isLoading);
  const [form] = Form.useForm();

  const columns = [
    {
      title: t("Admin.Dashboard.TC01"),
      dataIndex: "name",
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: t("Admin.Dashboard.TC02"),
      dataIndex: "activeSince",
      sorter: (a, b) => {
        return String(a?.activeSince).localeCompare(b?.activeSince);
      },
    },
    {
      title: t("Admin.Dashboard.TC03"),
      dataIndex: "ActiveProjects",
      sorter: (a, b) => a.ActiveProjects.length - b.ActiveProjects.length,
      render: (data, record) => data.toString(),
    },
    {
      title: "",
      dataIndex: "edit",
      render: (data, record) => (
        <Button
          style={{
            color: "#00A8CC",
            borderColor: "#00A8CC",
            borderRadius: 5,
            marginRight: 10,
          }}
          onClick={() => showEditDrawer(record)}
        >
          <EditFilled />{" "}
        </Button>
      ),
      align: "center",
    },
  ];

  // edit drawer open
  const showEditDrawer = (record) => {
    setSelectEngineerId(record.engineer_id);
    setSelectDrawer(false);
    form.setFieldsValue(record);
    setAddrawer(true);
  };

  // New drawer open
  const showAddDrawer = () => {
    form.resetFields();
    setSelectDrawer(true);
    setAddrawer(true);
  };

  // close drawer
  const closeAddDrawer = () => {
    setAddrawer(false);
    form.resetFields();
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = AllEngineersDetials.filter((item) =>
        item.name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setAllEngineers(tempSearchData);
    } else {
      setAllEngineers(AllEngineersDetials);
    }
  };

  //delete engineer
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(data.map((item) => deleteEngineer(item)))
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Engineer.Delete"));
          setData([]);
          fetchAllEngineersData();
        }
      })
      .catch((err) => {
        dispatch(setLoader(false));
        console.log(err);
      });
  };

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  //create new engineer
  const CreateNewEngineer = async (data) => {
    setIsCreateLoading(true);
    Auth.signUp({
      username: data.email,
      password: "12345678",
      attributes: {
        email: data.email,
        given_name: data.first_name,
        family_name: data.last_name,
      },
    })
      .then((res) => {
        let formData = form.getFieldsValue([
          "first_name",
          "last_name",
          "email",
        ]);
        let org_id = JSON.parse(
          window.localStorage.getItem("user-attributes")
        ).sub;
        let user_sub = res.userSub;
        let inputData = { org_id, ...formData, user_sub };

        createEngineer(inputData)
          .then((res) => {
            setAddrawer(false);
            setIsCreateLoading(false);
            if (res.message === "Success") {
              message.success(t("SuccessfullMsg.Admin.Engineer.Create"));
              fetchAllEngineersData();
            }
          })
          .catch((err) => {
            setIsCreateLoading(false);
            setAddrawer(false);
            message.error(t("SomthingWentWrong"));
          });
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  // Update engineer from here
  const UpdateEngineer = async () => {
    setIsCreateLoading(true);
    let UpdatedData = form.getFieldsValue();

    let data = {
      id: selectEngineerId,
      data: {
        first_name: UpdatedData.first_name,
        last_name: UpdatedData.last_name,
        email: UpdatedData.email,
      },
    };

    await updateEngineer(data)
      .then((res) => {
        setAddrawer(false);
        setIsCreateLoading(false);
        if (res.message === "Success") {
          message.success(t("SuccessfullMsg.Admin.Engineer.Update"));
          fetchAllEngineersData();
        }
      })
      .catch((err) => {
        setIsCreateLoading(false);
        setAddrawer(false);
        message.error(t("SomthingWentWrong"));
      });
  };

  //fetch all engineer's details
  const fetchAllEngineersData = async () => {
    dispatch(setLoader(true));
    let tempArray = [];
    await getAllEngineers()
      .then((res) => {
        res.map((e) => {
          return tempArray.push({
            key: e.user_sub,
            engineer_id: e.engineer_id,
            name: e.first_name + " " + e.last_name,
            first_name: e.first_name,
            last_name: e.last_name,
            email: e.email,
            activeSince: moment(e.created_at).format("l"),
            ActiveProjects: [],
          });
        });
        getAllProjects()
          .then((projects) => {
            Object.entries(projects).map(([key, item]) => {
              item?.assigned_engineer_id.map((engineer_id) => {
                tempArray.map((t) => {
                  if (t.key === engineer_id) {
                    t.ActiveProjects.push(item?.name);
                  }
                  return t;
                });
                return engineer_id;
              });
              return item;
            });
            dispatch(setLoader(false));
            setAllEngineersDetials(tempArray);
            return setAllEngineers(tempArray);
          })
          .catch((err) => {
            message.error(t("SomthingWentWrong"));
            dispatch(setLoader(false));
          });
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  useEffect(() => {
    fetchAllEngineersData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Admin.Dashboard.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showAddDrawer}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                    marginRight: 10,
                  }}
                >
                  <PlusOutlined />
                </Button>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>

        <Spin spinning={isLoading}>
          <div style={{ padding: "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={allEngineers}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {selectDrawer
              ? t("Admin.Dashboard.DrawerTitle")
              : t("Admin.Dashboard.DrawerEditTitle")}
          </span>
        }
        placement="right"
        onClose={closeAddDrawer}
        visible={addDrawer}
      >
        <Form
          layout="vertical"
          form={form}
          onFinish={selectDrawer ? CreateNewEngineer : UpdateEngineer}
          requiredMark={false}
        >
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Dashboard.DrawerFirstName")}
              </strong>
            }
            name="first_name"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Engineer.E01"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Admin.Engineer.DrawerFirstName")}
            />
          </Form.Item>

          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Dashboard.DrawerLastName")}
              </strong>
            }
            name="last_name"
            rules={[
              {
                required: true,
                message: t("Error.Admin.Engineer.E02"),
              },
            ]}
          >
            <Input
              placeholder={t("Placeholder.Admin.Engineer.DrawerLastName")}
            />
          </Form.Item>

          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("Admin.Dashboard.DrawerEmail")}
              </strong>
            }
            name="email"
            rules={[
              { required: true, message: t("Error.Admin.Engineer.E03") },
              {
                pattern: /^[a-z0-9+._-]+@[a-z0-9+.-]+\.[a-z]{2,4}$/,
                message: t("Error.Admin.Engineer.E04"),
              },
            ]}
          >
            <Input placeholder={t("Placeholder.Admin.Engineer.DrawerEmail")} />
          </Form.Item>
          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setAddrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("Cancle")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
              loading={createLoading}
            >
              {selectDrawer ? t("Create") : t("Update")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
