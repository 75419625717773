import "./index.css";
import Routes from "./Routers/Routes";
import "./i18/i18n";
import { Suspense } from "react";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import { Provider } from "react-redux";
import { store, persistor } from "./store/index";
import { PersistGate } from "redux-persist/integration/react";

Amplify.configure(config);
function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Suspense fallback="Loading...">
            <Routes />
          </Suspense>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
