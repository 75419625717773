import React, { useState } from "react";
import { Button, Col, Drawer, Row, Table, Form, Input, Select } from "antd";
import { useTranslation } from "react-i18next";

const columns = [
  {
    title: "File",
    dataIndex: "File",
  },
  {
    title: "Satus",
    dataIndex: "Satus",
  },
  {
    title: "Type",
    dataIndex: "Type",
  },
  {
    title: "Error Message",
    dataIndex: "ErrorMessage",
  },
];

const data = [
  {
    key: "1",
    File: "alvara.pdf",
    Satus: "Ok",
    Type: "Alvara",
    ErrorMessage: "Passes Validity Date",
  },
  {
    key: "2",
    File: "National.jpg",
    Satus: "Rejected",
    Type: "ID",
    ErrorMessage: "Misssing National ID Number",
  },
  {
    key: "3",
    File: "Voter.pdf",
    Satus: "Ok",
    Type: "Seguro",
    ErrorMessage: "unreadable",
  },
];

const { Option } = Select;

export default function Documents() {
  const { t } = useTranslation();

  const [editDrawer, setEditDrawer] = useState(false);

  const closeEditDrawer = () => {
    setEditDrawer(false);
  };

  return (
    <>
      <div style={{ borderRadius: 7, background: "white", marginBottom: 10 }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("CompanyRepresentative.Documents.Title")}
              </h2>
            </Col>
          </Row>
        </div>

        <div style={{ padding: "25px" }}>
          <Form layout="vertical">
            <Row gutter={20}>
              <Col span={8} lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  label={
                    <strong style={{ color: "#7E7E7E" }}>
                      {t("CompanyRepresentative.Documents.FormEntity")}
                    </strong>
                  }
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={8} lg={8} md={8} sm={24} xs={24}>
                <Form.Item
                  label={
                    <strong style={{ color: "#7E7E7E" }}>
                      {t("CompanyRepresentative.Documents.FormFiles")}
                    </strong>
                  }
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Button
              style={{
                background: "#00A8CC",
                color: "white",
                border: 0,
                borderRadius: 8,
                width: 140,
                height: 33,
                fontWeight: 600,
                fontSize: 15,
              }}
            >
              {t("Create")}
            </Button>
          </Form>
        </div>
      </div>

      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("CompanyRepresentative.Documents.Header")}
              </h2>
            </Col>
          </Row>
        </div>

        <div style={{ padding: "25px" }}>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            className="document-table"
          />
        </div>
      </div>

      <Drawer
        className="editDocument"
        title={
          <span style={{ color: "#303030", fontWeight: 600 }}>
            {t("CompanyRepresentative.Documents.EditDrawerTitle")}
          </span>
        }
        placement="right"
        onClose={closeEditDrawer}
        visible={editDrawer}
      >
        <Form layout="vertical">
          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("CompanyRepresentative.Documents.EditDrawerName")}
              </strong>
            }
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("CompanyRepresentative.Documents.EditDrawerDocument")}
              </strong>
            }
          >
            <Select defaultValue="License" style={{ width: "100%" }}>
              <Option value="License">
                {t("CompanyRepresentative.Documents.EditDrawerDocumentOption1")}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("CompanyRepresentative.Documents.EditDrawerValue")}
              </strong>
            }
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <strong style={{ color: "#7E7E7E" }}>
                {t("CompanyRepresentative.Documents.EditDrawerType")}
              </strong>
            }
          >
            <Select defaultValue="59875600" style={{ width: "100%" }}>
              <Option value="59875600">
                {t("CompanyRepresentative.Documents.EditDrawerTypeOption1")}
              </Option>
            </Select>
          </Form.Item>

          <div
            style={{
              position: "absolute",
              right: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#EFF4F9",
              textAlign: "left",
              display: "flex",
            }}
          >
            <Button
              className="secondary-btn"
              onClick={() => setEditDrawer(false)}
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                marginRight: 8,
                height: "40px",
                background: "transparent",
                borderColor: "#00A8CC",
                color: "#00A8CC",
              }}
            >
              {t("CSVButton")}
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              style={{
                fontSize: 16,
                borderRadius: 8,
                flexGrow: 1,
                flexBasis: 0,
                height: "40px",
                background: "#00A8CC",
                borderColor: "#00A8CC",
                fontWeight: 600,
                color: "white",
              }}
            >
              {t("CompanyRepresentative.Documents.EditDocumentButton")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}
