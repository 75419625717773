import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Input, Modal, message, Spin } from "antd";
import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import {
  getAllDocuments,
  getAllAdminComapnies,
  deleteDocument,
  getAllAdminDocumentTypes,
  removeFileToS3,
} from "../../../Api/restApi";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../store/Actions";
import { Storage } from "aws-amplify";

const { confirm } = Modal;
const { Search } = Input;

export default function Document(props) {
  const { t } = useTranslation();
  const [companiesData, setCompaniesData] = useState([]);
  const [labelData, setLabelData] = useState([]);
  const { isLoading } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [allLabelData, setAllLabelData] = useState([]);
  const [labelIdData, setLabelIdData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: t("Engineer.Documents.TC01"),
      dataIndex: "file_name",
      sorter: (a, b) => a.file_name.length - b.file_name.length,
      render: (data, record) => (
        <Button
          style={{ border: "none", color: "#1890FF" }}
          onClick={() => showModal(record)}
        >
          {data}
        </Button>
      ),
    },
    {
      title: t("Engineer.Documents.TC02"),
      dataIndex: "company_id",
      sorter: (a, b) => a.company_id.length - b.company_id.length,
      render: (data, record) =>
        companiesData.filter((c) => c.company_id === record.company_id)?.[0]?.[
          "company_name"
        ],
      hidden: props.tableCss ? true : false,
    },
    {
      title: t("Engineer.Documents.TC06"),
      dataIndex: "first_name",
      sorter: (a, b) => a.first_name.length - b.first_name.length,
      render: (data, record) => record.first_name + " " + record.last_name,
    },
    {
      title: t("Engineer.Documents.TC03"),
      dataIndex: "document_name",
      sorter: (a, b) => a.document_name.length - b.document_name.length,
    },
    {
      title: t("Engineer.Documents.TC04"),
      dataIndex: "created_at",
      sorter: (a, b) => {
        return String(a?.created_at).localeCompare(b?.created_at);
      },
    },
    {
      title: t("Engineer.Documents.TC05"),
      dataIndex: "has_pii",
      sorter: (a, b) => a.has_pii.length - b.has_pii.length,
    },
  ].filter((item) => !item.hidden);

  // selecting checkbox
  const rowSelection = {
    onChange: (data) => {
      setData(data);
    },
  };

  const showConfirm = () => {
    confirm({
      closable: true,
      centered: true,
      title: <strong>{t("DeleteConfirmation")}</strong>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleDelete();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  // Fetch all label's details
  const fetchAllLabels = async (document_type) => {
    dispatch(setLoader(true));
    let allLabels;
    await getAllDocuments()
      .then((res) => {
        allLabels = res;
        getAllAdminComapnies().then((res) => {
          setCompaniesData(res);
          let tempArray = [];
          if (allLabels !== null) {
            allLabels.map(async (data) => {
              tempArray.push({
                key: data.id,
                company_id: data.company_id,
                file_name: data.file_name.split("/")[2],
                file_path: data.file_name,
                first_name: data.first_name || "",
                last_name: data.last_name || "",
                created_at: moment(data.created_at).format("l"),
                document_name: document_type.filter(
                  (item) => item.id === data.document_type
                )[0]?.["name"],
                document_type: data.document_type,
                has_pii:
                  document_type.filter(
                    (item) => item.id === data.document_type
                  )[0]?.["has_pii"] === true
                    ? "Yes"
                    : "No",
              });
              return tempArray;
            });
          }
          Promise.all(
            tempArray.map((item) => {
              return gets3link(item.file_path);
            })
          )
            .then((data) =>
              data.map((item, index) => {
                return (tempArray[index]["file_link"] = item);
              })
            )
            .catch((err) => {
              console.log(err);
            });
          dispatch(setLoader(false));
          if (props?.company_id) {
            let filterData = tempArray.filter(
              (item) => item.company_id === props.company_id
            );
            props.setDocument(filterData.length);
            setAllLabelData(filterData);
            setLabelData(filterData);
          } else {
            setAllLabelData(tempArray);
            setLabelData(tempArray);
          }
        });
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(true));
      });
  };

  const gets3link = (key) => {
    return Storage.get(key);
  };

  // Fetch all documents type
  const fetchAllDocumentTypes = async () => {
    dispatch(setLoader(true));
    await getAllAdminDocumentTypes()
      .then((response) => {
        setDocumentType(response);
        fetchAllLabels(response);
      })
      .catch((err) => {
        message.error(t("SomthingWentWrong"));
        dispatch(setLoader(false));
      });
  };

  // Delete label
  const handleDelete = async () => {
    dispatch(setLoader(true));
    await Promise.all(
      data.map((item) => {
        RemoveFileToS3(item);
        return deleteDocument(item);
      })
    )
      .then((res) => {
        if (res[0].message === "Success") {
          message.success(t("SuccessfullMsg.Engineer.Labelling.Delete"));
          setData([]);
          fetchAllLabels(documentType);
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
      });
  };

  const RemoveFileToS3 = async (item) => {
    let removeData = labelData.filter((value) => value.key === item);
    let file = removeData[0]?.file_path;

    await removeFileToS3(file)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Handle search
  const handleSearch = (sreachValue) => {
    if (sreachValue !== "") {
      let tempSearchData = allLabelData.filter((label) =>
        label.file_name.toLowerCase().includes(sreachValue.toLowerCase())
      );
      setLabelData(tempSearchData);
    } else {
      setLabelData(allLabelData);
    }
  };

  const showModal = (record) => {
    setLabelIdData(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    fetchAllDocumentTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ borderRadius: 7, background: "white" }}>
        <div style={{ borderBottom: "2px solid #EFF4F8" }}>
          <Row style={{ padding: "10px 25px" }}>
            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <h2
                style={{ marginBottom: 0, color: "#252525", fontWeight: 600 }}
              >
                {t("Engineer.Documents.Title")}
              </h2>
            </Col>

            <Col span={12} md={12} lg={12} sm={24} xs={24}>
              <Row justify="end">
                <div className="searchbar">
                  <Search
                    placeholder={t("Search")}
                    onChange={(e) => handleSearch(e.target.value)}
                    style={{ width: 200 }}
                  />
                </div>
                <Button
                  onClick={showConfirm}
                  style={{
                    color: "#00A8CC",
                    borderColor: "#00A8CC",
                    borderRadius: 5,
                  }}
                  disabled={data.length > 0 ? false : true}
                >
                  <DeleteFilled />
                </Button>
              </Row>
            </Col>
          </Row>
        </div>
        <Spin spinning={isLoading}>
          <div style={{ padding: props.tableCss ? "0px" : "25px" }}>
            <Table
              locale={{ emptyText: t("NoData") }}
              rowSelection={rowSelection}
              columns={columns}
              dataSource={labelData}
              bordered
              className="document-table"
            />
          </div>
        </Spin>
      </div>

      <Modal
        title={labelIdData?.file_name}
        footer=""
        visible={isModalVisible}
        style={{ width: "35%" }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <iframe
          src={labelIdData?.file_link}
          title="myFrame"
          style={{ height: "500px" }}
          scrolling="no"
          width="100%"
          frameborder="0"
        ></iframe>
      </Modal>
    </>
  );
}
